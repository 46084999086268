import {useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {initialQueryState, KTSVG} from '../../../../../../../_metronic/helpers'
import {useQueryRequest} from 'src/app/context/job-order/QueryRequestProvider'
import { useQueryResponse } from 'src/app/context/job-order/QueryResponseProvider'

const PlottingsListFilter = () => {
  const {updateState} = useQueryRequest()
  const {isLoading} = useQueryResponse()
  const [jobNo, setJobNob] = useState<string | undefined>()
  const [client, setClient] = useState<string | undefined>()
  const [plottingNo, setPlottingNo] = useState<string | undefined>()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const resetData = () => {
    updateState({filter: undefined, ...initialQueryState})
  }

  const filterData = () => {
    updateState({
		filter: { jobNo, plottingNo: plottingNo, client: client },
      ...initialQueryState,
    })
  }

  return (
    <>
      {/* begin::Filter Button */}
		<a
			href='#'
			className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
			data-kt-menu-trigger='click'
			data-kt-menu-placement='bottom-end'
			data-kt-menu-flip='top-end'
		>
			<KTSVG
				path='/media/icons/duotune/general/gen031.svg'
				className='svg-icon-5 svg-icon-gray-500 me-1'
			/>
			Filter
		</a>
      {/* end::Filter Button */}
      {/* begin::SubMenu */}
		  <div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' style={{ backgroundColor: 'white' }} data-kt-menu='true'>
        {/* begin::Header */}
        <div className='px-7 py-5'>
          <div className='fs-5 text-dark fw-bolder'>Filter Options</div>
        </div>
        {/* end::Header */}

        {/* begin::Separator */}
        <div className='separator border-gray-200'></div>
        {/* end::Separator */}

        {/* begin::Content */}
        <div className='px-7 py-5' data-kt-user-table-filter='form'>
          {/* begin::Input group */}
          <div className='mb-10'>
				<label className='form-label fs-6 fw-bold'>Job No : </label>
				<input
					type="text"
					id='JobNo'
					className='form-control'
					placeholder="Job No"
					onChange={(e) => setJobNob(e.target.value)}
					value={jobNo}
				/>
          </div>
          {/* end::Input group */}

          {/* begin::Input group */}
          <div className='mb-10'>
				<label className='form-label fs-6 fw-bold'>Client : </label>
				<input
					type="text"
					id='client'
					className='form-control'
					placeholder="Client"
					onChange={(e) => setClient(e.target.value)}
					value={client}
				/>
          </div>

		<div className='mb-10'>
			<label className='form-label fs-6 fw-bold'>Plotting No : </label>
			<input
				type="text"
				id='plottingNo'
				className='form-control'
				placeholder="Plotting No"
				onChange={(e) => setPlottingNo(e.target.value)}
				value={plottingNo}
			/>
		</div>
          {/* end::Input group */}

          {/* begin::Actions */}
          <div className='d-flex justify-content-end'>
            <button
              type='button'
              disabled={isLoading}
              onClick={filterData}
              className='btn btn-light btn-active-light-primary fw-bold me-2 px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='reset'
            >
              Reset
            </button>
            <button
              disabled={isLoading}
              type='button'
              onClick={resetData}
              className='btn btn-primary fw-bold px-6'
              data-kt-menu-dismiss='true'
              data-kt-user-table-filter='filter'
            >
              Apply
            </button>
          </div>
          {/* end::Actions */}
        </div>
        {/* end::Content */}
      </div>
      {/* end::SubMenu */}
    </>
  )
}

export { PlottingsListFilter }
