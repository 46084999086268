/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useContext, useState, useEffect, useMemo } from 'react'
import { useQuery } from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQueryState,
} from 'src/_metronic/helpers'
import { useQueryRequestEVoucher } from './QueryRequestProvider'
import { EVoucher, evoucherDummyData, GetEVoucherResponse } from 'src/app/models/evoucher-model'
import { getEVouchers } from 'src/app/api/evoucher-api'
import { useAuth } from '../auth/AuthProvider'
import { ROLE_TYPE } from 'src/app/models/role-type'

const QueryResponseContext = createResponseContext<GetEVoucherResponse>(initialQueryResponse)
const QueryResponseEVoucherProvider: FC = ({ children }) => { 
  const { state } = useQueryRequestEVoucher()
  const [query, setQuery] = useState<string>(stringifyRequestQueryState(state))
  let updatedQuery = useMemo(() => stringifyRequestQueryState(state), [state])
   
  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    () => {
      return getEVouchers(query)
    },
    { cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false }
  )

  return (
    <QueryResponseContext.Provider value={{ isLoading: isFetching, refetch, response, query }}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponseEVoucher = () => useContext(QueryResponseContext)

const useQueryResponseEVoucherData = () => {
  const { response } = useQueryResponseEVoucher()
  const { currentUser } = useAuth()

  if (!response) {
    return []
  }

  // console.log('response ', response)
  let newData: any[] = []
  // console.log('originData  useQueryResponseEVoucherData', response?.data)  
  if (response?.data) {
    newData = response.data.map((originData, id) => {
      return {
        no: id + 1,
        id: originData.id,
        status: originData.voucher_status,
        companyName: originData.company_name,
        voucherNo: originData.voucher_no,
        jobId: originData.job_id,
        jobNo: originData.job_no,
        document: originData.documents,
        amount: originData.total_amount,
        userId: originData.userId,
        taxInput: originData.tax_input,
        isAcknowledge: originData.is_acknowledged,
        created_at: originData.created_at,
        totalPPN: originData.total_ppn ? parseInt(originData.total_ppn) : 0,
        totalPPH: originData.total_pph ? parseInt(originData.total_pph) : 0
      }
    })

    // console.log('updatedData ', newData)
  }

  let tempRoles: string[] = []
  currentUser?.role.forEach(role => {
    tempRoles.push(role.roleName)
  })


  if (tempRoles.includes(ROLE_TYPE.staff)) {
    /***
     * Untuk filter data yang ditampilkan, hanya voucher yg dibuat oleh user ini dan belum di acknowledge
     * Nantinya, akan lebih baik jika filter menggunakan query ketika memanggi API getEVouchers agar jumlah data yang dikembalikan sesuai dengan data yang sudah di filter
     */
    // newData = newData.filter((data) => (data.userId === currentUser?.id) && (data.isAcknowledge === 0))
    newData = newData.map((originData, id) => {
      return {
        ...originData,
        no: id + 1
      }
    })
  }

  return newData || []
}

const useQueryResponseEVoucherPagination = () => {
  const { state } = useQueryRequestEVoucher()
  const { currentUser } = useAuth()
  const defaultPaginationState: PaginationState = {
    links: [],
    ...initialQueryState,
  }
  const currPage = state.page
  const currLimit = state.limit

  const { response } = useQueryResponseEVoucher()

  if (!response || !response.count) {
    return defaultPaginationState
  }

  let tempRoles: string[] = []
  currentUser?.role.forEach(role => {
    tempRoles.push(role.roleName)
  })

  let totalData = response.count
  let newData = response?.data
  if (tempRoles.includes(ROLE_TYPE.staff)) {

    /***
     * Untuk filter data yang ditampilkan, hanya voucher yg dibuat oleh user ini dan belum di acknowledge
     * Nantinya, akan lebih baik jika filter menggunakan query ketika memanggi API getEVouchers agar jumlah data yang dikembalikan sesuai dengan data yang sudah di filter
     */
    // newData = newData?.filter((data) => (data.userId === currentUser?.id) && (data.is_acknowledged === 0))
    // totalData = newData?.length
  }



  let tempLastPage = 0
  let tempRemaining = totalData % currLimit
  if (tempRemaining === 0) {
    tempLastPage = totalData / currLimit
  } else {
    let tempNewCount = totalData - tempRemaining
    tempLastPage = tempNewCount / currLimit
    tempLastPage = tempLastPage + 1
  }

  let previousPage = 1;
  if (currPage !== 1 && tempLastPage > 0) {
    previousPage = currPage - 1
  }

  let nextPage = 1;
  if (currPage < tempLastPage) {
    nextPage = currPage + 1
  } else {
    nextPage = currPage
  }
  // console.log('currPage ', currPage)
  // console.log('previousPage ', previousPage)
  // console.log('nextPage ', nextPage)


  let tempLink = []
  tempLink.push({
    "url": null,
    "label": "&laquo; Previous",
    "active": false,
    "page": previousPage
  })
  for (let i = 1; i <= tempLastPage; i++) {
    tempLink.push({
      "url": `/?page=${i}`,
      "label": i.toString(),
      "active": i === currPage,
      "page": i
    })
  }
  tempLink.push({
    "url": `/?page=${currPage + 1}`,
    "label": "Next &raquo;",
    "active": false,
    "page": nextPage
  })

  // console.log('tempLink ', tempLink)
  let tempPagination = {
    "page": currPage,
    "first_page_url": "/?page=1",
    "from": 1,
    "last_page": tempLastPage,
    "links": tempLink,
    "next_page_url": "/?page=2",
    "limit": currLimit,
    "prev_page_url": null,
    "to": currLimit,
    "total": totalData
  }

  return tempPagination
}

const useQueryResponseEVoucherLoading = (): boolean => {
  const { isLoading } = useQueryResponseEVoucher()
  return isLoading
}

export {
  QueryResponseEVoucherProvider,
  useQueryResponseEVoucher,
  useQueryResponseEVoucherData,
  useQueryResponseEVoucherPagination,
  useQueryResponseEVoucherLoading,
}
