import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../_metronic/helpers'
import { CreateRoleRequest, UpdateRoleRequest, UsersQueryResponse } from '../models/role-model'
import * as authHelper from 'src/app/context/auth/AuthHelpers'
import { approveRealisationRequest, CreateRealisationRequest, RealisasiQueryResponse } from '../models/evoucher-realisasi-model'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DEV_API = process.env.REACT_APP_DEV_API

const BASE_URL = `${DEV_API}/realisation`

const getRealisasi = (query?: string): Promise<RealisasiQueryResponse> => {
  return axios.get(`${BASE_URL}?${query}`, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((d: AxiosResponse<RealisasiQueryResponse>) => d.data)
}

const getRealisasiById = (id: ID) => {
  return axios.get(`${BASE_URL}/${id}`, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((response) => {
    return response
  })
}

const createRealisation = (data: CreateRealisationRequest) => {
  return axios.post(`${BASE_URL}`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((response) => {
    return response
  })
}

const insertRealisationDoc = (data: FormData) => {
  return axios.post(`${BASE_URL}/document`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}

const approveRealisation = (data: approveRealisationRequest) => {
  return axios.post(`${BASE_URL}/approve`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}

const rejectRealisation = (data: approveRealisationRequest) => {
  return axios.post(`${BASE_URL}/reject`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}

const updateEVoucherRealisasi = (id: ID, data: UpdateRoleRequest) => {
  return axios.put(`${BASE_URL}/${id}`, data).then((response) => {
    return response
  })
}

const deleteEVoucherRealisasi = (id: ID) => {
  return axios.delete(`${BASE_URL}/${id}`).then((response) => {
    return response
  })
}

const getVRealisationLogs = (id: string) => {
  return axios.get(`${BASE_URL}/status/${id}`, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((response) => {
    return response
  })
}

const getRealisasiPDF = (id: string) => {
  return axios
    .get(`${BASE_URL}/download/${id}`, {
      headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
    })
    .then((response) => {
       return response
    })
}

const getVRealisationPaymentProof = (id: string) => {
  return axios.get(`${BASE_URL}/proof/${id}`, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((response) => {
    return response
  })
}

export { getRealisasi, getRealisasiById, deleteEVoucherRealisasi, createRealisation, insertRealisationDoc, updateEVoucherRealisasi, approveRealisation, rejectRealisation, getVRealisationLogs, getRealisasiPDF, getVRealisationPaymentProof }
