import { useCallback, useEffect, useState } from 'react'
import { KTSVG } from 'src/_metronic/helpers'
import { Buttons } from 'src/_metronic/layout/components/button/Button'
import {updateBulkStatus } from 'src/app/api/invoice-bulk-api'
import { getInvoiceTTD } from 'src/app/api/invoice-ttd-api'
import Swal from 'sweetalert2'

interface modalInfo {
    onCancel(): void,
    bulk_id: number,
}

const InvBulkApproveModal = ({bulk_id, onCancel}: modalInfo) => {

  const swalConfirmation = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			cancelButton: 'btn btn-danger',
		},
		buttonsStyling: true,
	})
  
  const [invDetails, setInvDetails] = useState<any>([])

  const GetBulkDetail = useCallback(async () => {
    let query: any = new URLSearchParams({
      limit: "9999",
      offset: "0",
      type: "bulk-invoice",
      bulk_id: String(bulk_id)
    }).toString()
    const resultData = await getInvoiceTTD(query)
    
    setInvDetails(resultData?.data)
  }, [])

  useEffect(() => {
    GetBulkDetail()
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [GetBulkDetail])

  const submitHandler = async (event: any) => {
    event.preventDefault()
    try {
      const formData = {
        invoices: invDetails.map((value: any) => value?.inv?.id),
        invDoctype: "approval",
        paymentStatus: "paid"
      }

      const res = await updateBulkStatus(formData)
      console.log('res here', res)
      if(res?.status === 200){
        await swalConfirmation.fire('Success!', res?.data?.message, 'success')
        window.location.reload();
      }
      else{
        await swalConfirmation.fire('Failed!', res?.data?.message, 'error')
        window.location.reload();
      }
    } catch (error) {
      await swalConfirmation.fire('Failed!', "Gagal", 'error')
    }
  }

  const currencyFormatter = (val: number) => {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      maximumFractionDigits: 0
    }).format(val)
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-5}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            
            <div className='modal-header'>
                {/* begin::Modal title */}
                <h2 className='fw-bolder'>Approval Confirmation</h2>
                {/* end::Modal title */}

                {/* begin::Close */}
                <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    style={{ cursor: 'pointer' }}
                    onClick={onCancel}
                >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
                {/* end::Close */}
            </div>

            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              
            
                <form className='form' onSubmit={submitHandler}>
                    <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                    >
                        <h2>
                            <b>Approve this invoices ?</b>
                        </h2>

                        <br></br>

                        <div className='fv-row mb-7'>
                            {/* begin::Label */}
                          {invDetails.map((value: any) => {
                            return <div className="row">
                              <div className="col-6"><p>{value?.inv?.invNo}</p></div>
                              <div className="col-6"><p>{currencyFormatter(Number(value?.inv?.invTotal))}</p></div>
                            </div>
                          })}
                        </div>

                        <div className='text-center pt-15'>
                        <div className='text-center'>
                            <Buttons
                            type='secondary'
                            text={'Cancel'}
                            onClickButton={onCancel}
                            ></Buttons>
                            <Buttons type='primary' text={'Confirm'} onClickButton={() => {}}></Buttons>
                        </div>
                    </div>
                    </div>
                </form>


            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export { InvBulkApproveModal }
