import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../_metronic/helpers'
import * as authHelper from 'src/app/context/auth/AuthHelpers'
import { UpdateRoleRequest } from '../models/role-model'
import { CreateVoucherRequest, EVoucherQueryResponse, rejectVoucherRequest, updateVoucherRequest } from '../models/evoucher-model'

const API_URL = process.env.REACT_APP_THEME_API_URL
const DEV_API = process.env.REACT_APP_DEV_API

const BASE_URL = `${DEV_API}/voucher`
const BANK_URL = `${DEV_API}/bank`

const getEVouchers = (query?: string): Promise<EVoucherQueryResponse> => { 
  return axios.get(`${BASE_URL}?${query}`, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((d: AxiosResponse<EVoucherQueryResponse>) => d.data)
}

const getEVoucherById = (id: ID) => {
  return axios.get(`${BASE_URL}/${id}`, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((response) => {
    return response
  })
}

const getEVoucherLogs = (id: ID) => {
  return axios.get(`${BASE_URL}/${id}/status`, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((response) => {
    return response
  })
}

const createEVoucher = (data: CreateVoucherRequest) => { 
  return axios.post(`${BASE_URL}`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}
 
const updateEVoucher = (id: ID, data: updateVoucherRequest) => {
  return axios.put(`${BASE_URL}/${id}`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((response) => {
    return response
  })
}

const deleteEVoucher = (id: ID) => {
  return axios.delete(`${BASE_URL}/${id}`).then((response) => {
    return response
  })
}

const getEVoucherNo = () => {
  return axios.get(`${BASE_URL}/voucher-no`, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }).then((response) => {
    return response
  })
}

const insertVoucherDoc = (data: FormData) => {
  return axios.post(`${BASE_URL}/document`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}

const approveVoucher = (data: rejectVoucherRequest) => {
  return axios.post(`${BASE_URL}/approve`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}

const rejectVoucher = (data: rejectVoucherRequest) => {
  return axios.post(`${BASE_URL}/reject`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}

const createPayment = (data: FormData) => {
  return axios.post(`${BASE_URL}/create-payment`, data, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}

const acknowledgeVoucher = (id: ID) => {
  let bodyRequest = {
    voucherId: id
  }

  return axios.post(`${BASE_URL}/acknowledge`, bodyRequest, {
    headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  }
  ).then((response) => {
    return response
  })
}

const getVoucherForExcel = (query: string) => {
  return axios
    .get(`${BASE_URL}?${query}`, {
      headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
    })
    .then((response) => { return response.data.data })
 
  // return axios.get(`${BASE_URL}?${query}`, {
  //   headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
  // }).then((d: AxiosResponse<EVoucherQueryResponse>) => d.data)
}

const getBanks = () => {
  return axios
    .get(`${BANK_URL}`)
    .then((response) => {
      return response.data.data;
    })
}

const getEvoucherPDF = (id: string) => {
  return axios
    .get(`${BASE_URL}/download/${id}`, {
      headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
    })
    .then((response) => {
       return response
    })
}

export { getEVouchers, getVoucherForExcel, getEVoucherById, getEVoucherLogs, deleteEVoucher, getEVoucherNo, createEVoucher, insertVoucherDoc, updateEVoucher, approveVoucher, rejectVoucher, createPayment, acknowledgeVoucher, getBanks, getEvoucherPDF }
