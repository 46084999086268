import { lazy, FC, Suspense } from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'
import { MenuTestPage } from '../pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import {TruckingDashboardWrapper} from '../pages/dashboard/TruckingDashboardWrapper'
import InvTTDPage from '../modules/transaction/invoice-ttd/InvoiceTTDPage'
import InvoiceBulkPage from '../modules/transaction/invoice-bulk/InvoiceBulkPage'

const PrivateRoutes = () => {
  const BuilderPageWrapper = lazy(() => import('../pages/layout-builder/BuilderPageWrapper'))
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))
  const AreasPage = lazy(() => import('../modules/master/master-area/AreaPage'))
  const FeaturesPage = lazy(() => import('../modules/master/master-feature/FeaturePage'))
  const RolesPage = lazy(() => import('../modules/master/master-role/RolePage'))
  const RealisationPage = lazy(() => import('../modules/master/master-evoucher/RealisationPage'))

  // const MasterVehicle = lazy(() => import('../modules/master/master-vehicle/VehiclesPage'))

  // Menu Master Data Company
  const MasterCategories = lazy(() => import('../modules/master/master-pricing/CategorisPage'))
  const MasterDocument = lazy(() => import('../modules/master/master-document/DocumentsPage'))
  const MasterVehicle = lazy(() => import('../modules/master/master-vehicle/VehiclesPage'))

  const MasterUsers = lazy(() => import('../modules/master/master-user/UsersPage'))

  // Menu Master Data Company
  const MasterCompany = lazy(() => import('../modules/master/master-company/CompaniesPage'))
  const MasterEmployee = lazy(() => import('../modules/master/master-employee/EmployeesPage'))
  const MasterDriver = lazy(() => import('../modules/master/master-driver/DriversPage'))
  const MasterAllowance = lazy(() => import('../modules/master/master-allowance/AllowancesPage'))
  const MasterUangJalan = lazy(() => import('../modules/master/master-uang-jalan/UangJalanPage'))
  const MasterClient = lazy(() => import('../modules/master/master-client/ClientsPage'))
  const MasterClientContract = lazy(() => import('../modules/master/master-client-contract/ContractsPage'))
  const MasterClientPricing = lazy(() => import('../modules/master/master-client-pricing/ClientPricingPage'))
  const MasterInbound = lazy(() => import('../modules/transaction/inbound/InboundPage'))
  const MasterInboundDetail = lazy(() => import('../modules/transaction/inbound-detail/InboundDetailsPage'))
  const SuratJalan = lazy(() => import('../modules/transaction/surat-jalan/SuratJalanPage'))
  const Dispatch = lazy(() => import('../modules/transaction/dispatch/DispatchPage'))
  const Invoice = lazy(() => import('../modules/transaction/invoice/InvoicePage'))
  const Depo = lazy(() => import('../modules/master/master-depo/DeposPage'))

  // Menu Master Data Equipment
  const MasterEquipment = lazy(() => import('../modules/master/master-equipment/EquipmentsPage'))
  // Menu Master Data Job Order
  const MasterJobOrder = lazy(() => import('../modules/transaction/master-job-order/JobOrdersPage'))

  // Menu Transaction Allowance Payment
  const TransactionAllowancePayment = lazy(() => import('../modules/transaction/allowance-payment/AllowancePaymentsPage'))
  // Menu Transaksi Plotting
  const TransactionPlotting = lazy(() => import('../modules/transaction/plotting/PlottingsPage'))

  // Menu Transaksi Equipment Return
  const TransactionEquipmentReturn = lazy(() => import('../modules/transaction/equipment-return/EquipmentReturnsPage'))


  // Menu Transaksi Equipment Order
  const TransactionEquipmentOrder = lazy(() => import('../modules/transaction/equipment-order/EquipmentOrdersPage'))

  // Menu Transaksi Equipment Check
  const TransactionEquipmentCheck = lazy(() => import('../modules/transaction/equipment-check/EquipmentChecksPage'))

  // Menu Transaksi Equipment Stock
  const TransactionEquipmentStock = lazy(() => import('../modules/transaction/equipment-stock/EquipmentStocksPage'))

  // Menu Transaksi Maintenance
  const TransactionMaintenance = lazy(() => import('../modules/transaction/maintenance/MaintenancesPage'))

  // Menu Transaksi Storing
  const TransactionStoring = lazy(() => import('../modules/transaction/storing/StoringsPage'))

  // Menu Master Data Sparepart
  const MasterSparepart = lazy(() => import('../modules/master/master-sparepart/SparepartsPage'))

  // Menu Transaksi Sparepart Stock
  const TransactionSparepartStock = lazy(() => import('../modules/transaction/sparepart-stock/SparepartStocksPage'))

  const MasterCountry = lazy(() => import('../modules/master/master-country/CountriesPage'))

  // Menu Master Shipper Importir
  const MasterShipperImportir = lazy(() => import('../modules/master/master-shipper-importir/ShippersPage'))

  const MasterVendorType = lazy(() => import('../modules/master/master-vendor-type/VendorTypesPage'))
  const MasterVendor = lazy(() => import('../modules/master/master-vendor/VendorsPage'))

  const MasterHsCode = lazy(() => import('../modules/master/master-hscode/HsCodesPage'))

  const MasterProduct = lazy(() => import('../modules/master/master-product/ProductsPage'))

  const MasterClientPricingImp = lazy(() => import('../modules/master/master-client-pricing-imp/ClientPricingsPage'))

  const MasterJobOrderImp = lazy(() => import('../modules/transaction/master-job-order-imp/JobOrderImpsPage'))

  const MasterUOM = lazy(() => import('../modules/master/master-uom/UOMsPage'))

  // e-voucher module
  const PpnPage = lazy(() => import('../modules/evoucher/master-ppn/PpnPage'))
  const PphPage = lazy(() => import('../modules/evoucher/master-pph/PphPage'))
  const EVoucherPage = lazy(() => import('../modules/master/master-evoucher/EVoucherPage'))

  const InvoiceImportir = lazy(() => import('../modules/transaction/invoice-importir/InvoiceImpsPage'))

  const DepoPage = lazy(() => import('../modules/transaction/depo/DepoPage'))
  const BrokerPage = lazy(() => import('../modules/master/master-broker/BrokerPage'))

  const RefundUJPage = lazy(() => import('../modules/transaction/refund-uj/RefundUJPage'))
  const InvoicePaidPage = lazy(() => import('../modules/transaction/invoice-paid/InvoicePaidPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}

        {/* start test */}
        <Route path='trucking/dashboard' element={<TruckingDashboardWrapper />} />
        {/* end test */}

        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-vehicle/*'
          element={
            <SuspensedView>
              <MasterVehicle />
            </SuspensedView>
          }
        />

        <Route
          path='master/driver/*'
          element={
            <SuspensedView>
              <MasterDriver />
            </SuspensedView>
          }
        />

        <Route
          path='master/allowance/*'
          element={
            <SuspensedView>
              <MasterAllowance />
            </SuspensedView>
          }
        />

        <Route
          path='master/uang-jalan/*'
          element={
            <SuspensedView>
              <MasterUangJalan />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-area/*'
          element={
            <SuspensedView>
              <AreasPage />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-documents/*'
          element={
            <SuspensedView>
              <MasterDocument />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-pricing/*'
          element={
            <SuspensedView>
              <MasterCategories />
            </SuspensedView>
          }
        />

        <Route
          path='master/employee/*'
          element={
            <SuspensedView>
              <MasterEmployee />
            </SuspensedView>
          }
        />

        <Route
          path='master/company/*'
          element={
            <SuspensedView>
              <MasterCompany />
            </SuspensedView>
          }
        />


        <Route
          path='master/master-area/*'
          element={
            <SuspensedView>
              <AreasPage />
            </SuspensedView>
          }
        />
        <Route
          path='master/master-feature/*'
          element={
            <SuspensedView>
              <FeaturesPage />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-user/*'
          element={
            <SuspensedView>
              <MasterUsers />
            </SuspensedView>
          }
        />

        <Route
          path='master/role/*'
          element={
            <SuspensedView>
              <RolesPage />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-equipment/*'
          element={
            <SuspensedView>
              <MasterEquipment />
            </SuspensedView>
          }
        />
        <Route
          path='master/master-job-orders/*'
          element={
            <SuspensedView>
              <MasterJobOrder />
            </SuspensedView>
          }
        />

        <Route
          path='master/client/*'
          element={
            <SuspensedView>
              <MasterClient />
            </SuspensedView>
          }
        />

        <Route
          path='master/client-contract/*'
          element={
            <SuspensedView>
              <MasterClientContract />
            </SuspensedView>
          }
        />

        <Route
          path='master/client-pricing/*'
          element={
            <SuspensedView>
              <MasterClientPricing />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/refund/*'
          element={
            <SuspensedView>
              <RefundUJPage />
            </SuspensedView>
          }
        />
        <Route
          path='transaction/inbound/*'
          element={
            <SuspensedView>
              <MasterInbound />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/inbound-detail/*'
          element={
            <SuspensedView>
              <MasterInboundDetail />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/surat-jalan/*'
          element={
            <SuspensedView>
              <SuratJalan />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/dispatch/*'
          element={
            <SuspensedView>
              <Dispatch />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/invoice/*'
          element={
            <SuspensedView>
              <Invoice />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/invoice-status/*'
          element={
            <SuspensedView>
              <InvoicePaidPage />
            </SuspensedView>
          }
        />

        <Route
          path='master/depo/*'
          element={
            <SuspensedView>
              <Depo />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/allowance-payment/*'
          element={
            <SuspensedView>
              <TransactionAllowancePayment />
            </SuspensedView>
          }
        />

        <Route
          path='master/plotting/*'
          element={
            <SuspensedView>
              <TransactionPlotting />

            </SuspensedView>
          }
        />

        <Route
          path='transaction/equipment-return/*'
          element={
            <SuspensedView>
              <TransactionEquipmentReturn />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-evoucher/realisation/*'
          element={
            <SuspensedView>
              <RealisationPage />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/equipment-order/*'
          element={
            <SuspensedView>
              <TransactionEquipmentOrder />

            </SuspensedView>
          }
        />

        <Route
          path='transaction/equipment-check/*'
          element={
            <SuspensedView>
              <TransactionEquipmentCheck />

            </SuspensedView>
          }
        />

        <Route
          path='transaction/equipment-stock/*'
          element={
            <SuspensedView>
              <TransactionEquipmentStock />

            </SuspensedView>
          }
        />

        <Route
          path='transaction/maintenance/*'
          element={
            <SuspensedView>
              <TransactionMaintenance />

            </SuspensedView>
          }
        />

        <Route
          path='transaction/storing/*'
          element={
            <SuspensedView>
              <TransactionStoring />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-sparepart/*'
          element={
            <SuspensedView>
              <MasterSparepart />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/sparepart-stock/*'
          element={
            <SuspensedView>
              <TransactionSparepartStock />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-countries/*'
          element={
            <SuspensedView>
              <MasterCountry />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-shipper-importir/*'
          element={
            <SuspensedView>
              <MasterShipperImportir />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-vendor-type/*'
          element={
            <SuspensedView>
              <MasterVendorType />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-vendor/*'
          element={
            <SuspensedView>
              <MasterVendor />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-hscode/*'
          element={
            <SuspensedView>
              <MasterHsCode />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-product/*'
          element={
            <SuspensedView>
              <MasterProduct />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-client-pricing-imp/*'
          element={
            <SuspensedView>
              <MasterClientPricingImp />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-job-order-imp/*'
          element={
            <SuspensedView>
              <MasterJobOrderImp />

            </SuspensedView>
          }
        />

        <Route
          path='master/master-uom/*'
          element={
            <SuspensedView>
              <MasterUOM />

            </SuspensedView>
          }
        />


        <Route
          path='master/master-ppn*'
          element={
            <SuspensedView>
              <PpnPage />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-pph*'
          element={
            <SuspensedView>
              <PphPage />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-evoucher/evoucher/*'
          element={
            <SuspensedView>
              <EVoucherPage />
            </SuspensedView>
          }
        />

        <Route
          path='master/master-broker/*'
          element={
            <SuspensedView>
              <BrokerPage />
            </SuspensedView>
          }
        />

        <Route
          path='transaction/depo/*'
          element={
            <SuspensedView>
              <DepoPage />
            </SuspensedView>
          }
        />
        <Route
          path='transaction/invoice-ttd/*'
          element={
            <SuspensedView>
              <InvTTDPage />
            </SuspensedView>
          }
        />
        <Route
          path='transaction/invoice-bulk/*'
          element={
            <SuspensedView>
              <InvoiceBulkPage />
            </SuspensedView>
          }
        />

		<Route
          path='transaction/invoice-imp/*'
          element={
            <SuspensedView>
              <InvoiceImportir />
            </SuspensedView>
          }
        />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
