import customAxios from './custom-axios'

const INVOICE_TTD_URL = process.env.REACT_APP_DEV_API + `/invoice-bulk`

const getInvoiceTTD = async (query: string) => {
  return customAxios.get(`${INVOICE_TTD_URL}/get?${query}`).then((d) => d?.data)
}

const addInvoiceTTD = async (form: {invoices: string[], invDoctype: string}) => {
  return customAxios.post(`${INVOICE_TTD_URL}/create`, form).then((res) => res?.data)
}

const uploadTTDDocs = async (form: FormData, id: number) => {
  return customAxios.post(`${INVOICE_TTD_URL}/doc/${id}?type=ttd`, form).then((res) => res)
}

export {getInvoiceTTD, addInvoiceTTD, uploadTTDDocs}