/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import { getUserByToken, login } from '../../../api/auth-api'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
import { useAuth } from '../../../context/auth/AuthProvider'
import { FEATURE_TYPE } from 'src/app/models/auth-type'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  email: 'someemail@gmail.com',
  password: 'R4hasia!',
}

const initialUserValue = {
  id: '3676819d-f0d2-4234-af97-3f2db021d352',
  username: 'mdiAdmin01',
  status: 1,
  email: '21someemail@gmail.com',
  role: [
    {
      id: '80ce85af-31e9-40dd-a2ce-1bae60e1a42b',
      roleName: 'role21a',
      feature: [
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdArea,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdCompany,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdCompanyLoc,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdDocument,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdDriver,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdDriverAdditionalCost,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdDriverAllowance,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdDriverUangJajan,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdEmployee,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdFeature,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdPricing,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdPricingCategory,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdPricingType,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdPricingPrice,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdRole,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdUser,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdJobOrder,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdVehicle,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdVehicleBrand,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdVehicleModel,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.mdVehicleType,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '1901ddb4-3b2b-4ca0-b497-0afbde51826e',
          featureName: FEATURE_TYPE.Equipment,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 }
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdClient,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdClientContract,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdClientPricing,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.inbound,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.inboundDetail,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.suratJalan,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.dispatch,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.AllowancePayment,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdPlotting,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.invoice,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.depo,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.EquipmentReturn,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.EquipmentOrder,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.EquipmentCheck,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.EquipmentStock,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.Maintenance,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.Storing,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdPricingUnit,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.sparePart,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.SparepartStock,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdCountry
          ,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdShipperImportir
          ,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },


        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdVendorType
          ,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdVendor
          ,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdHsCode
          ,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdProduct
          ,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdClientPricingImp
          ,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdJobOrderImp
          ,

          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdUOM,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },


        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdPpn,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.mdPph,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.eVoucher,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

		{
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.invoiceImp,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },

        {
          id: '24bb019e-836f-41ee-89fa-1d004243f040',
          featureName: FEATURE_TYPE.broker,
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },


      ],
    },
    {
      id: '8c1d317b-e374-481b-9f22-9a0f32b8cdf4',
      roleName: 'role1',
      feature: [
        {
          id: 'c38026f8-eb8f-4e6e-b799-41428b2c39ba',
          featureName: 'MD Documents',
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
      ],
    },
    {
      id: 'd37975b1-1f57-439b-a25a-01070b525481',
      roleName: '21',
      feature: [
        {
          id: 'c38026f8-eb8f-4e6e-b799-41428b2c39ba',
          featureName: 'MD Documents',
          permissions: { rCreate: 1, rRead: 1, rUpdate: 1, rDelete: 1 },
        },
      ],
    },
  ],
  employee: {
    id: 'dc5e4174-6371-4a08-abb9-71ba996ea02b',
    name: 'James',
    company: { id: '04ba8ff9-7fd6-4a2d-81a8-fb2a52b70bf0', companyName: 'comp1' },
    // company: {id: 'a0bdf887-eec1-4d43-b0d8-94ba28e448e4', companyName: 'comp1'},
  },
}
/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        if (values.email === initialValues.email && values.password === initialValues.password) {
          // console.log('equal with initiall')
          setCurrentUser(initialUserValue)
        } else {
          const { data: auth } = await login(values.email, values.password)
          // console.log('auth login res ', auth)
          saveAuth(auth.data.token)
          const { data: user } = await getUserByToken(auth.api_token)
          // console.log('user ', user)
          setCurrentUser(user.data)
        }
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login detail is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Selamat Datang di Metaseti</h1>
        {/* <div className='text-gray-400 fw-bold fs-4'>
          New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link>
        </div> */}
      </div>
      {/* begin::Heading */}

      {formik.status ? (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      ) : (
        // <div className='mb-10 bg-light-info p-8 rounded'>
        //   <div className='text-info'>
        //     Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to
        //     continue.
        //   </div>
        // </div>
        <div></div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
        <input
          placeholder='Email'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
          type='email'
          name='email'
          autoComplete='off'
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <span role='alert'>{formik.errors.email}</span>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <div className='d-flex justify-content-between mt-n5'>
          <div className='d-flex flex-stack mb-2'>
            {/* begin::Label */}
            <label className='form-label fw-bolder text-dark fs-6 mb-0'>Password</label>
            {/* end::Label */}
            {/* begin::Link */}
            {/* <Link
              to='/auth/forgot-password'
              className='link-primary fs-6 fw-bolder'
              style={{ marginLeft: '5px' }}
            >
              Forgot Password ?
            </Link> */}
            {/* end::Link */}
          </div>
        </div>
        <input
          type='password'
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control form-control-lg form-control-solid',
            {
              'is-invalid': formik.touched.password && formik.errors.password,
            },
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Action */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid}
        >
          {!loading && <span className='indicator-label'>Continue</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>

        {/* begin::Separator */}
        {/* Hamjah <div className='text-center text-muted text-uppercase fw-bolder mb-5'>or</div> */}
        {/* end::Separator */}

        {/* begin::Google link */}
        {/* Hamjah <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
            className='h-20px me-3'
          />
          Continue with Google
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* Hamjah <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100 mb-5'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/facebook-4.svg')}
            className='h-20px me-3'
          />
          Continue with Facebook
        </a> */}
        {/* end::Google link */}

        {/* begin::Google link */}
        {/* Hamjah <a href='#' className='btn btn-flex flex-center btn-light btn-lg w-100'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
            className='h-20px me-3'
          />
          Continue with Apple
        </a> */}
        {/* end::Google link */}
      </div>
      {/* end::Action */}
    </form>
  )
}
