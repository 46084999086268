import { useEffect, useState } from 'react'
import { KTSVG } from 'src/_metronic/helpers'
import { Buttons } from 'src/_metronic/layout/components/button/Button'
import { uploadTTDDocs } from 'src/app/api/invoice-ttd-api'
import Swal from 'sweetalert2'

interface modalInfo {
    onCancel(): void,
    ttd_id: number,
}

const InvTTDUploadDocModal = ({ttd_id, onCancel}: modalInfo) => {

  const swalConfirmation = Swal.mixin({
		customClass: {
			confirmButton: 'btn btn-success',
			cancelButton: 'btn btn-danger',
		},
		buttonsStyling: true,
	})
  
  const [files, setFiles] = useState<any>(null)

  useEffect(() => {
    document.body.classList.add('modal-open')
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [])

  const submitHandler = async (event: any) => {
    event.preventDefault()
    try {
      const formData = new FormData()

      formData.append('photoFile', files)
      const res = await uploadTTDDocs(formData, ttd_id)
      console.log('res here', res)
      if(res?.status === 200){
        await swalConfirmation.fire('Success!', res?.data?.message, 'success')
        window.location.reload();
      }
      else{
        await swalConfirmation.fire('Failed!', res?.data?.message, 'error')
        window.location.reload();
      }
    } catch (error) {
      await swalConfirmation.fire('Failed!', "Gagal", 'error')
    }
  }

  const handleLogoChange = (event: any) => {
    setFiles(event?.target?.files[0])
  }

  return (
    <>
      <div
        className='modal fade show d-block'
        id='kt_modal_add_user'
        role='dialog'
        tabIndex={-5}
        aria-modal='true'
      >
        {/* begin::Modal dialog */}
        <div className='modal-dialog modal-dialog-centered mw-650px'>
          {/* begin::Modal content */}
          <div className='modal-content'>
            
            <div className='modal-header'>
                {/* begin::Modal title */}
                <h2 className='fw-bolder'>Upload Invoice TTD Docs</h2>
                {/* end::Modal title */}

                {/* begin::Close */}
                <div
                    className='btn btn-icon btn-sm btn-active-icon-primary'
                    data-kt-users-modal-action='close'
                    style={{ cursor: 'pointer' }}
                    onClick={onCancel}
                >
                    <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                </div>
                {/* end::Close */}
            </div>

            {/* begin::Modal body */}
            <div className='modal-body scroll-y mx-5 mx-xl-15 my-7'>
              
            
                <form className='form' onSubmit={submitHandler}>
                    <div
                    className='d-flex flex-column scroll-y me-n7 pe-7'
                    id='kt_modal_add_user_scroll'
                    data-kt-scroll='true'
                    data-kt-scroll-activate='{default: false, lg: true}'
                    data-kt-scroll-max-height='auto'
                    data-kt-scroll-dependencies='#kt_modal_add_user_header'
                    data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
                    data-kt-scroll-offset='300px'
                    >
                        <h2>
                            <b>Upload Document TTD Group {ttd_id}</b>
                        </h2>

                        <br></br>

                        <div className='fv-row mb-7'>
                            {/* begin::Label */}
                            <input
                              type='file'
                              id='broker_logo'
                              className='form-control'
                              placeholder='Upload Photo'
                              onChange={(e) => handleLogoChange(e)}
                            />
                        </div>

                        <div className='text-center pt-15'>
                        <div className='text-center'>
                            <Buttons
                            type='secondary'
                            text={'Close'}
                            onClickButton={onCancel}
                            ></Buttons>
                            <Buttons type='primary' text={'Submit'} onClickButton={() => {}}></Buttons>
                        </div>
                    </div>
                    </div>
                </form>


            </div>
            {/* end::Modal body */}
          </div>
          {/* end::Modal content */}
        </div>
        {/* end::Modal dialog */}
      </div>
      {/* begin::Modal Backdrop */}
      <div className='modal-backdrop fade show'></div>
      {/* end::Modal Backdrop */}
    </>
  )
}

export { InvTTDUploadDocModal }
