import {Route, Routes, Outlet} from 'react-router-dom'
import InvoiceBulkList from './invoice-bulk-list/InvoiceBulkList';
import InvoiceBulkCreate from './invoice-bulk-list/invoice-bulk-create/InvoiceBulkCreate';
import InvoiceBulkDetail from './invoice-bulk-list/invoice-bulk-detail/InvoiceBulkDetail';

const InvoiceBulkPage = () => {
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='list-invoice'
            element={
              <>
                <InvoiceBulkList />
              </>
            }
          />

          <Route
            path='create-bulk'
            element={
              <>
                <InvoiceBulkCreate/>
              </>
            }
          />
          <Route
            path='detail-bulk/:id'
            element={
              <>
                <InvoiceBulkDetail/>
              </>
            }
          />
        </Route>
      </Routes>
    )
  }
  
  export default InvoiceBulkPage;