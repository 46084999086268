/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { TruckingDashboardRequest } from 'src/app/context/trucking-dashboard/QueryRequestProvider'
import { Link } from 'react-router-dom'
import { string } from 'yup'

interface ResProps {
  jobOrder: string
  plotting: string
  dispatchOut: string
  dispatchIn: string
  invoice: string
  invoice_total: string
  invoice_paid: string
  invoice_paid_total: string
  ppn: string
  pph: string
  total_uj_job: string,
  total_uj_job_paid: string,
}

interface cardProps {
  className: string
  color: string
}

const MixedWidgetTrucking = ({ className, color }:cardProps) => {

  const [result, setResult] = useState<ResProps | null>(null)

  useEffect(() => {
    const getDashboard = async () => {
      try {
        const result = await TruckingDashboardRequest('')
        setResult(result)
        // console.log(result)
      } catch (error) {
        console.log(error)
      }
    }
    getDashboard()
  }, [])
    return (
        <div className={`card ${className}`}>
          {/* begin::Header */}
          <div className={`card-header border-0 py-5 bg-${color}`}>
            {/* <h3 className='card-title fw-bolder text-white'>awiawkoaw</h3> */}
            <div className='card-toolbar'>
              {/* begin::Menu */}
              <button
                type='button'
                className='btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color- border-0 me-n3'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
              </button>
              <Dropdown1 />
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Header */}
          {/* begin::Body */}
          <div className='card-body p-0'>
            {/* begin::Chart */}
            <div
              className={`mixed-widget-2-chart card-rounded-bottom bg-${color}`}
            ></div>
            {/* end::Chart */}
            {/* begin::Stats */}
            <div className='card-p mt-n20 position-relative'>
              {/* begin::Row */}
              <div className='row g-0'>
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 me-7 mb-7'>
                  <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-1'>
                        {result?.jobOrder}
                      </a>
                    </div>
                  </div>
                  <Link to='/master/master-job-orders/joborders'>
                    <a href='' className='text-primary fw-bold fs-6'>
                      Total Job Order
                    </a>
                  </Link>
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                  <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr075.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-1'>
                        {result?.plotting}
                      </a>
                    </div>
                  </div>
                  <Link to='/master/plotting/list-plotting'>
                    <a href='' className='text-primary fw-bold fs-6'>
                      Total Plotting
                    </a>
                  </Link>
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}

              {/* begin::Row */}
              <div className='row g-0'>
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 me-7 mb-7'>
                  <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-1'>
                        {result?.total_uj_job}
                      </a>
                    </div>
                  </div>
                  <Link to='/master/master-job-orders/joborders'>
                    <a href='#' className='text-primary fw-bold fs-6'>
                      Total Uang Jalan Job
                    </a>
                  </Link>
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-1'>
                        {result?.total_uj_job_paid}
                      </a>
                    </div>
                  </div>
                  <Link to='/transaction/allowance-payment/list-payments'>
                    <a href='#' className='text-primary fw-bold fs-6'>
                      Total Uang Jalan Job Paid
                    </a>
                  </Link>
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}

              {/* begin::Row */}
              <div className='row g-0'>
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 me-7 mb-7'>
                  <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-1'>
                        {result?.dispatchOut}
                      </a>
                    </div>
                  </div>
                  <Link to='/transaction/dispatch/list-dispatch'>
                    <a href='#' className='text-primary fw-bold fs-6'>
                      Total Dispatch Out
                    </a>
                  </Link>
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-1'>
                        {result?.dispatchIn}
                      </a>
                    </div>
                  </div>
                  <Link to='/transaction/dispatch/list-dispatch'>
                    <a href='#' className='text-primary fw-bold fs-6'>
                      Total Dispatch In
                    </a>
                  </Link>
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}
              {/* begin::Row */}
              <div className='row g-0'>
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7 me-7'>
                  
                  <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs027.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-4 mt-2'>
                        {result?.invoice} / {result?.invoice_total}
                      </a>
                    </div>
                  </div>

                  <Link to='/transaction/invoice/list-invoice'>
                    <a href='#' className='text-primary fw-bold fs-6 mt-2'>
                      Total Invoice
                    </a>
                  </Link>
                  
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>
                  
                  <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs027.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-4 mt-2'>
                        {result?.invoice_paid} / {result?.invoice_paid_total}
                      </a>
                    </div>
                  </div>

                  <Link to='/transaction/invoice/list-invoice'>
                    <a href='#' className='text-primary fw-bold fs-6 mt-2'>
                      Total Invoice Paid
                    </a>
                  </Link>
                  
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}
              {/* begin::Row */}
              <div className='row g-0'>
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 me-7 mb-7'>
                  <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                        <a href='#' className='text-primary fw-bold fs-6'>
                        {result?.ppn}
                      </a>
                    </div>
                  </div>
                  
                  <Link to='/transaction/invoice/list-invoice'>
                    <a href='#' className='text-primary fw-bold fs-6'>
                      Total PPN
                    </a>
                  </Link>
                  
                </div>
                {/* end::Col */}
                {/* begin::Col */}
                <div className='col bg-light-primary px-6 py-8 rounded-2 mb-7'>

                  <div className="row">
                    <div className="col-xxl-3">
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr075.svg'
                        className='svg-icon-3x svg-icon-primary d-block my-2'
                      />
                    </div>
                    <div className="col-xxl-9">
                      <a href='#' className='text-primary fw-bold fs-6'>
                        {result?.pph}
                      </a>
                    </div>
                  </div>
                  
                  <Link to='/transaction/invoice/list-invoice'>
                    <a href='#' className='text-primary fw-bold fs-6'>
                      Total PPH
                    </a>
                  </Link>
                  
                </div>
                {/* end::Col */}
              </div>
              {/* end::Row */}
            </div>
            {/* end::Stats */}
          </div>
          {/* end::Body */}
        </div>
      )
}

export { MixedWidgetTrucking }
