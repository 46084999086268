import React from 'react'
import {useLayout} from '../../core/LayoutProvider'
import {Toolbar1} from './Toolbar1'
import { Toolbar2 } from './Toolbar2'

type ToolbarProps = {
  children?: any
  type: string
}

function Toolbar(props: ToolbarProps) {
  const {config} = useLayout()

  if (props.type === 'default') {
    return <Toolbar2 />
  } else {
    return props.children
  }
  // switch (config.toolbar.layout) {
  //   case 'toolbar1':
  //     return <Toolbar1 {props}/>

  //   default:
  //     return <Toolbar1 />
  // }
}

export {Toolbar}
