import { ID, Response } from '../../_metronic/helpers'

// Declare kebutuhan form Depo
export type Depo = {
  id?: ID
  no?: number
  depoName?: string,
  depoAddress?: string,
  depoLiner?: string,
  depoAccNo?: number,
  depoAccName?: string,
  bankId?: string,
  name?: string
}

export type DepoQueryResponse = Response<Array<Depo>>

export const initialDepo: Depo = {
  depoName: '',
  depoAddress: '',
  depoLiner: '',
  depoAccNo: 0,
  depoAccName: '',
  bankId: '',
  name: '',
}

// Depo Document
export type Hour = {
  id?: ID
  no?: number
  depoId?: string,
  days?: number
  startTime?: string
  endTime?: string,
  freeday?:string
}

export type HourQueryResponse = Response<Array<Hour>>

export const initialHour: Hour = {
  depoId: '',
  days: 0,
  startTime: '',
  endTime: '',
  freeday:'false'
}

// Depo Harga
export type Harga = {
  id?: ID
  no?: number
  depoId?: string,
  price?: string
  priceName?: string
  size?: string,
}

export type HargaQueryResponse = Response<Array<Harga>>

export const initialHarga: Harga = {
  depoId: '',
  price: '',
  priceName: '',
  size: '',
}

export type depoField = {
  tabDepo: string
  tabJam: string
  tabHarga: string
}

export const initialDepoField: depoField = {
  tabDepo: '',
  tabJam: '',
  tabHarga: '',
}

export const initialStatusDepoField: depoField = {
  tabDepo: '',
  tabJam: '',
  tabHarga: '',
}

