import { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useTable, ColumnInstance, Column } from 'react-table'
import { KTCardBody } from 'src/_metronic/helpers'
import Pagination from 'src/_metronic/layout/components/pagination'
import { BrokerCustomHeader } from './column/BrokerCustomHeader'
import { CustomHeaderColumn } from './column/CustomHeaderColumn'
import { CustomRow } from './column/CustomRow'
import { getInvoiceTTD } from 'src/app/api/invoice-ttd-api'
// import { InvTTDUploadDocModal } from '../component/InvoiceTTDUploadDocModal'
import dateFormat from 'dateformat'
import { InvBulkUploadDocModal } from '../invoice-bulk-create/component/InvoiceBulkUploadDocModal'
import {InvBulkApproveModal } from '../invoice-bulk-create/component/InvBulkApproveModal'
import { InvBulkRejectModal } from '../invoice-bulk-create/component/InvBulkRejectModal'
import { InvoiceBulkPDF } from '../CetakInvoiceBulk'
import { auto } from '@popperjs/core'

const InvoiceBulkTable = () => {
    const navigate = useNavigate()
    const [columns, setColumns] = useState<Column<any>[]>([])
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [limit, _] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currBulkId, setCurrBulkId] = useState<number>(0)
    const [isUploadModalShow, setIsUploadModalShow] = useState<boolean>(false)
    const [isApproveModalShow, setIsApproveModalShow] = useState<boolean>(false)
    const [isRejectModalShow, setIsRejectModalShow] = useState<boolean>(false)

    const getInvoiceBulkData = useCallback(async () => {
      try {
        let query: any = new URLSearchParams({
          limit: String(limit),
          offset: String((currentPage - 1) * limit),
          type: "bulk-invoice"
        }).toString()
        const data = await getInvoiceTTD(query)
        // Create a map to track unique bulkIds
        const uniqueBulkIds = new Set<number>();
        // Filter data to include only unique bulkId objects
        const uniqueData = data?.data.filter((obj: { bulkId: number }) => {
          if (uniqueBulkIds.has(obj.bulkId)) {
            return false; // Duplicate bulkId, skip
          }
          uniqueBulkIds.add(obj.bulkId);
          return true; // Unique bulkId, include
        });
        setTotalData(data?.count)
        setData(uniqueData)
      } catch (error) {
        //
      }
    }, [currentPage, limit])
    useEffect(() => {
      getInvoiceBulkData()
    }, [currentPage, getInvoiceBulkData]);

    const closeModalUpload = () => {
      setIsUploadModalShow(false)
    }

    const closeModalApprove = () => {
      setIsApproveModalShow(false)
    }
    const closeModalReject = () => {
      setIsRejectModalShow(false)
    }

    // set data here

    useEffect(() => {
      const tempColumns = [
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Inv Bulk No' className='min-w-125px' />
          ),
          id: 'inv_id',
          // Cell: ({ ...props }) => `INV-BULK-${props?.row?.original?.inv?.invDetail[0]?.client?.clientCode}-${dateFormat(props?.row?.original?.bulkCreatedAt, 'mmdd')}${props?.row?.original?.bulkId}`
          Cell: ({...props}) => (
            <span
              onClick={() => {
                navigate('../detail-bulk/' + props?.row?.original?.bulkId)
              }}
              style={{cursor: 'pointer', color: '#6DA8FF', textDecorationLine: 'underline'}}
            >
              INV-BULK-{props?.row?.original?.inv?.invDetail[0]?.client?.clientCode}-
              {dateFormat(props?.row?.original?.bulkCreatedAt, 'mmdd')}
              {props?.row?.original?.bulkId}
            </span>
          ),
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Client' className='min-w-125px' />
          ),
          id: 'clientName',
          Cell: ({...props}) => `${props?.row?.original?.inv?.invDetail[0]?.client?.clientName}`,
        },
        // {
        //   Header: (props: any) => (
        //     <BrokerCustomHeader tableProps={props} title='Bulk Group' className='min-w-100px' />
        //   ),
        //   id: 'bulk_group',
        //   Cell: ({...props}) => `${props?.row?.original?.bulkId}`,
        // },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Created At' className='min-w-100px' />
          ),
          id: 'created_at',
          Cell: ({...props}) => `${dateFormat(props?.row?.original?.bulkCreatedAt, 'dd-mm-yyyy')}`,
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Uploaded File' className='min-w-125px' />
          ),
          id: 'uploaded_file',
          Cell: ({ ...props }) => ( !props?.row?.original?.invDocFileBulk?.includes("no-image") &&
            <div className="row" style={{ width: 50}} title='Download'>
              <div className="col-6" style={{cursor: 'pointer'}}>
                <img src='/media/icons/icon-action/download-icon.svg' onClick={() => {
                  window.open(props?.row?.original?.invDocFileBulk, "_blank")
                }} alt='none'/>
              </div>
            </div>
          )
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Status' className='min-w-125px' />
          ),
          id: 'status',
          Cell: ({...props}) => {
            if (
              props?.row?.original?.paymentStatus === 'need_approval' &&
              props?.row?.original?.invDocFileBulk === 'temp-files'
            )
              return 'Awaiting File Upload'
            else if (
              props?.row?.original?.paymentStatus === 'need_approval' &&
              props?.row?.original?.invDocFileBulk !== 'temp-files'
            )
              return 'Need Approval'
            else if (props?.row?.original?.paymentStatus === 'paid') return 'Paid'
            else return 'Rejected'
          },
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Operation' className='min-w-50px' />
          ),
          id: 'actions',
          Cell: ({...props}) => (
            <div className='row' style={{width: 100}} title='Approval'>
              {props?.row?.original?.paymentStatus !== "paid" && 
                (
                  <>
                    <div className='col-3' style={{cursor: 'pointer'}}>
                      <img
                        src='/media/icons/icon-menu/evoucher-clipboard-check.svg'
                        onClick={() => {
                          setCurrBulkId(props?.row?.original?.bulkId)
                          setIsApproveModalShow(true)
                        }}
                        alt='none'
                      />
                    </div>
                    <div className='col-3' style={{cursor: 'pointer'}}>
                      <img
                        src='/media/icons/icon-menu/evoucher-clipboard-x.svg'
                        onClick={() => {
                          setCurrBulkId(props?.row?.original?.bulkId)
                          setIsRejectModalShow(true)
                        }}
                        alt='none'
                      />
                    </div>
                  </>
                )
              }
              <div className='col-3' style={{cursor: 'pointer'}}>
                <img
                  src='/media/icons/icon-action/ep_edit.svg'
                  onClick={() => {
                    setCurrBulkId(props?.row?.original?.bulkId)
                    setIsUploadModalShow(true)
                  }}
                  alt='none'
                />
              </div>
              <div className='col-3' style={{cursor: 'pointer'}}>
                <img
                  src='/media/icons/icon-action/download-icon.svg'
                  onClick={() => {
                    InvoiceBulkPDF(props?.row?.original?.bulkId)
                  }}
                  alt='none'
                />
              </div>
            </div>
          ),
          // Cell: ({ ...props }) => {
          //   if(props?.row?.original?.paymentStatus === "need_approval" && props?.row?.original?.invDocFileBulk !== "temp-files") {
          //     return (
          // <div className="row" style={{ width: 50}} title='Approval'>
          //   <div className="col-6" style={{cursor: 'pointer'}}>
          //     <img src='/media/icons/icon-menu/evoucher-clipboard-check.svg' onClick={() => {
          //       setCurrBulkId(props?.row?.original?.bulkId)
          //       setIsApproveModalShow(true)
          //     }} alt='none'/>
          //   </div>
          //   <div className="col-6" style={{cursor: 'pointer'}}>
          //     <img src='/media/icons/icon-menu/evoucher-clipboard-x.svg' onClick={() => {
          //       setCurrBulkId(props?.row?.original?.bulkId)
          //       setIsRejectModalShow(true)
          //     }} alt='none'/>
          //   </div>
          //   {}
          // </div>
          //     )
          //   }
          //   else if(props?.row?.original?.paymentStatus === "need_approval" && props?.row?.original?.invDocFileBulk === "temp-files"){
          //     return (
          // <div className="row" style={{ width: 50}} title='Upload File'>
          //   <div className="col-6" style={{cursor: 'pointer'}}>
          //     <img src='/media/icons/icon-action/ep_edit.svg' onClick={() => {
          //       setCurrBulkId(props?.row?.original?.bulkId)
          //       setIsUploadModalShow(true)
          //     }} alt='none'/>
          //   </div>
          //   <div className="col-6" style={{cursor: 'pointer'}}>
          //     <img src='/media/icons/icon-action/download-icon.svg' onClick={() => {
          //       InvoiceBulkPDF(props?.row?.original?.bulkId)
          //     }} alt='none'/>
          //   </div>
          // </div>

          //     )
          //   }
          //   else {
          //     return null
          //   }
          // },
        },
      ]
      setColumns(tempColumns)
    }, [])

    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
      columns,
      data,
    })

    return (
      <>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='kt_broker_table'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<any>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {totalData > 0 ? (
                  rows.map((row: any, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {!!totalData && (
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={totalData}
              pageSize={limit}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
          )}
        </KTCardBody>

        {isUploadModalShow && <InvBulkUploadDocModal bulk_id={currBulkId} onCancel={closeModalUpload} />}
        {isApproveModalShow && <InvBulkApproveModal bulk_id={currBulkId} onCancel={closeModalApprove} />}
        {isRejectModalShow && <InvBulkRejectModal bulk_id={currBulkId} onCancel={closeModalReject} />}
      </>
    )
}

export default InvoiceBulkTable