import { Dispatch, SetStateAction } from 'react'
import { employeeField, initialEmployeeField } from '../../../app/models/employee-model'
import { depoField, initialDepoField, initialStatusDepoField } from '../../../app/models/depo-model'

export type ID = undefined | null | number | string

export type PaginationState = {
  page: number
  limit: 10 | 30 | 50 | 100
  links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string
}

// 2023-02-20 percobaan hamjah start
export type SearchStateNew = {
  clientName?: string,
  jobNo?: string,
  plottingNo?: string,
  suratJalanNo?: string,
}

export type ExceltState = {
  excel?: unknown
}
// percobaan hamjah

export type Response<T> = {
  status?: any
  message?:any
  count?: any
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
  summary?: any
}

export type QueryState = PaginationState & SortState & FilterState & SearchState & SearchStateNew & ExceltState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export type Permission = {
  rCreate: number
  rRead: number
  rUpdate: number
  rDelete: number
}

export const initialQueryState: QueryState = {
  page: 1,
  limit: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export const initialPermission: Permission = {
  rCreate: 0,
  rRead: 0,
  rUpdate: 0,
  rDelete: 0,
}

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}

export type ListAreaContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
}

export const initialListArea: ListAreaContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}

export type ListFeatureContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>

}

export const initialListFeature: ListFeatureContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}

//29-07-2022 Hamjah
// MD Employee
export type ListEmployeeContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDetail?: ID
  setItemIdForDetail: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  employeeId?: string
  setEmployeeId: Dispatch<SetStateAction<string>>
  personalStatus?: string
  setPersonalStatus: Dispatch<SetStateAction<string>>
  educatiionStatus?: string
  setEducationStatus: Dispatch<SetStateAction<string>>
  workStatus?: string
  setWorkStatus: Dispatch<SetStateAction<string>>
  certificationStatus?: string
  setCertificationStatus: Dispatch<SetStateAction<string>>
  relativeStatus?: string
  setRelativeStatus: Dispatch<SetStateAction<string>>
  languageStatus?: string
  setLanguageStatus: Dispatch<SetStateAction<string>>
  contactStatus?: string
  setContactStatus: Dispatch<SetStateAction<string>>
  idField: employeeField
  setIdField: Dispatch<SetStateAction<employeeField>>
  tab?: string
  setTab: Dispatch<SetStateAction<string>>
  checkField?: number
  setCheckField: Dispatch<SetStateAction<number>>
  workAvailable?: boolean
  setWorkAvailable: Dispatch<SetStateAction<boolean>>
  certificationAvailable?: boolean
  setCertificationAvailable: Dispatch<SetStateAction<boolean>>
}

export const initialListEmployee: ListEmployeeContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDetail: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setIdField: () => { },
  idField: initialEmployeeField,
  setEmployeeId: () => { },
  setPersonalStatus: () => { },
  setEducationStatus: () => { },
  setWorkStatus: () => { },
  setCertificationStatus: () => { },
  setRelativeStatus: () => { },
  setLanguageStatus: () => { },
  setContactStatus: () => { },
  setTab: () => { },
  setCheckField: () => { },
  setWorkAvailable: () => false,
  setCertificationAvailable: () => false,
}

//MD Company
export type ListCompanyContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  companyId?: ID
  setCompanyId: Dispatch<SetStateAction<ID>>
}

export const initialListCompany: ListCompanyContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setCompanyId: () => { }
}

export type ListCompanyLocationContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  companyId?: ID
  setCompanyId: Dispatch<SetStateAction<ID>>
}

export const initialListCompanyLocation: ListCompanyLocationContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setCompanyId: () => { }
}

// MD Driver
export type ListDriverContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDetail?: ID
  setItemIdForDetail: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
}

export const initialListDriver: ListDriverContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDetail: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}

export type ListAddCostContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
}

export const initialListAddCost: ListAddCostContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}

export type ListAllowanceContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
}

export const initialListAllowance: ListAllowanceContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}

export type ListUangJalanContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDetail?: ID
  setItemIdForDetail: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
}

export const initialListUangJalan: ListUangJalanContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDetail: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}

export type ListClientContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  clientId?: string
  setClientId: Dispatch<SetStateAction<string>>
}

export const initialListClient: ListClientContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setClientId: () => { },
}

export type ListClientContractContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  clientId?: string
  setClientId: Dispatch<SetStateAction<string>>
  clientContractId?: string
  setClientContractId: Dispatch<SetStateAction<string>>
  clientName?: string
  setClientName: Dispatch<SetStateAction<string>>
}

export const initialListClientContract: ListClientContractContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setClientId: () => { },
  setClientContractId: () => { },
  setClientName: () => { },
}

export type inboundData = {
  id?: string,
  iddata?: string,
  no?: number,
  equipmentId?: string,
  inboundEqpqty?: number,
  inboundEqpphoto?: string,
  eqpName?: string,
  eqpSkuNo?: string,
  tempFile?: string,
}

export const initialInboundData: inboundData = {
  id: '',
  iddata: '',
  no: 0,
  equipmentId: '',
  inboundEqpqty: 0,
  inboundEqpphoto: '',
  eqpName: '',
  eqpSkuNo: '',
  tempFile: '',
}

export type ListInboundContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  inboundId?: string
  setInboundId: Dispatch<SetStateAction<string>>
  modalSku?: boolean
  setModalSku: Dispatch<SetStateAction<boolean>>
  tabelInbound?: boolean
  setTabelInbound: Dispatch<SetStateAction<boolean>>
  inboundList: inboundData[]
  setInboundList: Dispatch<SetStateAction<inboundData[]>>

   
  modalEquipmentList?: boolean
  setModalEquipmentList: Dispatch<SetStateAction<boolean>> 
  selectedEquipment: any[]
  setSelectedEquipment: Dispatch<SetStateAction<any[]>> 
  equipmentForInbound: inboundData[]
  setEquipmentForInbound: Dispatch<SetStateAction<inboundData[]>>
}

export const initialListInbound: ListInboundContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setInboundId: () => { },
  setModalSku: () => { },
  setTabelInbound: () => { },
  inboundList: [initialInboundData],
  setInboundList: () => { }, 
  
  setModalEquipmentList: () => { }, 
  selectedEquipment: [],
  setSelectedEquipment: () => [],
  equipmentForInbound: [initialInboundData],
  setEquipmentForInbound: () => [],
   
}

export type ListInboundDetailContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  inboundId?: string
  setInboundId: Dispatch<SetStateAction<string>>
  inboundDetailId?: string
  setInboundDetailId: Dispatch<SetStateAction<string>>
}

export const initialListInboundDetail: ListInboundDetailContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setInboundId: () => { },
  setInboundDetailId: () => { },
}

export type ListSuratJalanContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  suratJalanId?: string
  setSuratJalanId: Dispatch<SetStateAction<string>>
  plottingId?: string
  setPlottingId: Dispatch<SetStateAction<string>>
}

export const initialListSuratJalan: ListSuratJalanContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setSuratJalanId: () => { },
  setPlottingId: () => { },
}

export type ListDispatchContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDetail?: ID
  setItemIdForDetail: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  idPlotting?: ID
  setIdPlotting: Dispatch<SetStateAction<ID>>
  idPlottingOld?: ID
  setIdPlottingOld: Dispatch<SetStateAction<ID>>
  modalTonase?: boolean
  setModalTonase: Dispatch<SetStateAction<boolean>>
}

export const initialListDispatch: ListDispatchContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDetail: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setIdPlotting: () => { },
  setIdPlottingOld: () => { },
  setModalTonase: () => false
}

// Invoice
export type plottingForInvoice = {
  noSj?: string
  suratJalan?: string,
  iddata?: string,
  id?: string,
  plottingId?: string, 
  plottingNo?: string, 
  referenceNo?: string, 
  clientId?: string,
  clientPricingId?: string,
  jobOrderId?: string, 
  itemName?: string,
  quantity?: number,
  unit?: string, 
  price?: number, 
  total?: number,
  invNo?: string,
  invDate?: string,
  clientName?: string,
  jobNo?: string,
  noPolisi?: string,
  noContainer?: string,
  percentage?: string,
  file?: string
}

export const initialPlottingForInvoice: plottingForInvoice = {
  noSj: '',
  iddata: '',
  id: '',
  clientId: '',
  clientPricingId: '',
  plottingId: '',
  plottingNo: '',
  referenceNo: '',
  jobOrderId: '', 
  itemName: '',
  quantity: 0,
  unit: '', 
  price: 0,
  total: 0,
  invNo: '',
  invDate: '',
  clientName: '',
  jobNo: '',
  noPolisi: '',
  noContainer: '',
  percentage: '',
  file: ''
}

export type ListInvoiceContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDetail?: ID
  setItemIdForDetail: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  invoiceId?: ID
  setInvoiceId: Dispatch<SetStateAction<string>>
  modalHeader?: boolean
  setModalHeader: Dispatch<SetStateAction<boolean>>
  sourceData?: number
  setSourceData: Dispatch<SetStateAction<number>>
  modalPlottingList?: boolean
  setModalPlottingList: Dispatch<SetStateAction<boolean>> 
  selectedPlotting: any[]
  setSelectedPlotting: Dispatch<SetStateAction<any[]>> 
  plottingForInvoice: plottingForInvoice[]
  setPlottingForInvoice: Dispatch<SetStateAction<plottingForInvoice[]>>
  subTotal?: number
  setSubTotal: Dispatch<SetStateAction<number>>
  ppn?: number
  setPpn: Dispatch<SetStateAction<number>>
  pph?: number
  setPph: Dispatch<SetStateAction<number>>
  finalTotal?: number
  setFinalTotal: Dispatch<SetStateAction<number>>
  
  // invoice payment
  tab?: string
  setTab: Dispatch<SetStateAction<string>>
  plottingId?: string
  setPlottingId: Dispatch<SetStateAction<string>>
  jobId?: string
  setJobId: Dispatch<SetStateAction<string>>
}

export const initialListInvoice: ListInvoiceContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDetail: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setInvoiceId: () => { }, 
  setModalHeader: () => { },
  setSourceData: () => { },
  setModalPlottingList: () => { }, 
  selectedPlotting: [],
  setSelectedPlotting: () => [],
  plottingForInvoice: [initialPlottingForInvoice],
  setPlottingForInvoice: () => [],
  setSubTotal: () => 0,
  setPpn: () => 0,
  setPph: () => 0,
  setFinalTotal: () => 0,
  setTab: () => { },
  setPlottingId: () => {},
  setJobId: () => {}
}

export type ListDepoContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  itemIdForDetail?: ID
  setItemIdForDetail: Dispatch<SetStateAction<ID>>
  depoId?: string
  setDepoId: Dispatch<SetStateAction<string>>
  jamId?: number
  setJamId: Dispatch<SetStateAction<number>>
  tab?: string
  setTab: Dispatch<SetStateAction<string>>
  checkField?: number
  setCheckField: Dispatch<SetStateAction<number>>
  fieldTab: depoField
  setFieldTab: Dispatch<SetStateAction<depoField>>
  // fieldInputStatus: depoField
  // setFieldInputStatus: Dispatch<SetStateAction<depoField>>
  fieldNote?: string
  setFieldNote: Dispatch<SetStateAction<string>>
}

export const initialListDepo: ListDepoContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  setItemIdForDetail: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setDepoId: () => { },
  setJamId: () => { },
  setTab: () => { },
  setCheckField: () => { },
  fieldTab: initialDepoField,
  setFieldTab: () => { },
  // fieldInputStatus: initialStatusDepoField,
  // setFieldInputStatus: () => { },
  setFieldNote: () => { },
}

export type ListPlottingContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  itemIdForGet?: ID
  setItemIdForGet: Dispatch<SetStateAction<ID>>
}

export const initialListPlotting: ListPlottingContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setItemIdForGet: () => { },
}

export type ListDetailContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  itemIdForGet?: ID
  setItemIdForGet: Dispatch<SetStateAction<ID>>
}

export const initialListDetail: ListDetailContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setItemIdForGet: () => { },
}

export type ListEVoucherContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  itemIdForViewDoc?: ID
  setItemIdForViewDoc: Dispatch<SetStateAction<ID>>
}

export const initialListEVoucher: ListEVoucherContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setItemIdForViewDoc: () => { }
}

export type ListEVoucherRealisasiContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  itemIdForViewDoc?: ID
  setItemIdForViewDoc: Dispatch<SetStateAction<ID>>
}

export const initialListEVoucherRealisasi: ListEVoucherRealisasiContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setItemIdForViewDoc: () => { }
}

// MD ClientPricing
export type ListClientPricingContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void 
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  itemIdForDetail?: ID
  setItemIdForDetail: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
  selectedPrice?: string
  setSelectedPrice: Dispatch<SetStateAction<string>>
  modalPricingList?: boolean
  setModalPricingList: Dispatch<SetStateAction<boolean>>
}

export const initialListClientPricing: ListClientPricingContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setItemIdForDetail: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
  setSelectedPrice: () => { },
  setModalPricingList: () => { },
}

// 17-03-2023 e-voucher 
export type ListPpnContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
}

export const initialListPpn: ListPpnContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}

export type ListPphContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  // NULL => (CREATION MODE) | MODAL IS OPENED
  // NUMBER => (EDIT MODE) | MODAL IS OPENED
  // UNDEFINED => MODAL IS CLOSED
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  permission: Permission
  setPermission: Dispatch<SetStateAction<Permission>>
  isAllSelected: boolean
  disabled: boolean
  selectedDataIndex?: number
  setSelectedDataIndex: Dispatch<SetStateAction<number>>
}

export const initialListPph: ListPphContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  setSelectedDataIndex: () => { },
  isAllSelected: false,
  disabled: false,
  permission: initialPermission,
  setPermission: () => { },
}