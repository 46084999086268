import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from 'src/app/context/auth/AuthProvider'
import { UserFeatureModel } from 'src/app/models/auth-model'
import { FEATURE_TYPE } from 'src/app/models/auth-type'

export function AsideMenuMain() {
	const intl = useIntl()
	const { currentUser, logout, setCurrentUserFeature } = useAuth()

	const [isMdDocumentVisible, setIsMdDocumentVisible] = useState(false)
	const [isMdFeatureVisible, setIsMdFeatureVisible] = useState(false)
	const [isMdCompanyVisible, setIsMdCompanyVisible] = useState(false)
	const [isMdEmployeeVisible, setIsMdEmployeeVisible] = useState(false)
	const [isMdRoleVisible, setIsMdRoleVisible] = useState(false)
	const [isMdUserVisible, setIsMdUserVisible] = useState(false)

	const [isMdVehicleVisible, setIsMdVehicleVisible] = useState(false)
	const [isMdVBrandVisible, setIsMdVBrandVisible] = useState(false)
	const [isMdVModelVisible, setIsMdVModelVisible] = useState(false)
	const [isMdVTypeVisible, setIsMdVTypeVisible] = useState(false)

	const [isMdPricingVisible, setIsMdPricingVisible] = useState(false)
	const [isMdPricingCatVisible, setIsMdPricingCatVisible] = useState(false)
	const [isMdPricingTypeVisible, setIsMdPricingTypeVisible] = useState(false)
	const [isMdPricingPriceVisible, setIsMdPricingPriceVisible] = useState(false)
	const [isMdPricingUnitVisible, setIsMdPricingUnitVisible] = useState(false)

	const [isMdDriverVisible, setIsMdDriverVisible] = useState(false)
	const [isMdDriverAllowanceVisible, setIsMdDriverAllowanceVisible] = useState(false)
	const [isMdDriverUangJalanVisible, setIsMdDriverUangJalanVisible] = useState(false)
	const [isMdDriverAdditionalCostVisible, setIsMdAdditionalCostJalanVisible] = useState(false)
	const [isDepoVisible, setIsDepoVisible] = useState(false)

	const [isMdAreaVisible, setIsMdAreaVisible] = useState(false)
	const [isMdClientVisible, setIsMdClientVisible] = useState(false)
	const [isMdClientPricingVisible, setIsMdClientPricingVisible] = useState(false)

	const [isMdJobOrderVisible, setIsMdJobOrderVisible] = useState(false)
	const [isMdPlotting, setIsMdPlotting] = useState(false)
	const [isAllowancePaymentVisible, setIsAllowancePaymentVisible] = useState(false)
	const [isSuratJalanVisible, setIsSuratJalanVisible] = useState(false)
	const [isDispatchVisible, setIsDispatchVisible] = useState(false)
	const [isInvoiceVisible, setIsInvoiceVisible] = useState(false)

	const [isEquipmentVisible, setIsEquipmentVisible] = useState(false)
	const [isMdInboundVisible, setIsMdInboundVisible] = useState(false)
	const [isEquipmentReturnVisible, setIsEquipmentReturnVisible] = useState(false)
	const [isEquipmentOrderVisible, setIsEquipmentOrderVisible] = useState(false)
	const [isEquipmentCheckVisible, setIsEquipmentCheckVisible] = useState(false)
	const [isEquipmentStockVisible, setIsEquipmentStockVisible] = useState(false)
	const [isMaintenanceVisible, setIsMaintenanceVisible] = useState(false)
	const [isStoringVisible, setIsStoringVisible] = useState(false)

	const [isSparepartVisible, setIsSparepartVisible] = useState(false)
	const [isSparepartStockVisible, setIsSparepartStockVisible] = useState(false)
	const [isCountryVisible, setIsCountryVisible] = useState(false)
	const [isShipperImportirVisible, setIsShipperImportirVisible] = useState(false)
	const [isVendorTypeVisible, setIsVendorTypeVisible] = useState(false)
	const [isVendorVisible, setIsVendorVisible] = useState(false)
	const [isHsCodeVisible, setIsHsCodeVisible] = useState(false)
	const [isProductVisible, setIsProductVisible] = useState(false)
	const [isClientPricingImpVisible, setIsClientPricingImpVisible] = useState(false)
	const [isUOM, setIsUOM] = useState(false)
	const [isJobOrderImp, setIsJobOrderImp] = useState(false)

	const [isPpnVisible, setIsPpnVisible] = useState(false)
	const [isPphVisible, setIsPphVisible] = useState(false)
	const [isEVoucherVisible, setIsEVoucherVisible] = useState(false)


	const [isInvoiceImp, setIsInvoiceImp] = useState(false)

	const [isTruckingDashboardVisible, setIsTruckingDashboardVisible] = useState(false)

	const [isBrokerVisible, setIsBrokerVisible] = useState(false)

	const [isInvoiceTTDVisible, setIsInvoiceTTDVisible] = useState(false)

	const [isInvoiceBulkVisible, setIsInvoiceBulkVisible] = useState<boolean>(false);

	const [isRefundUJVisible, setIsRefundUJVisible] = useState<boolean>(false)

	const [isInvoicePaidVisible, setIsInvoicePaidVisible] = useState<boolean>(false);

	const [isInvoiceNotPaidVisible, setIsInvoiceNotPaidVisible] = useState<boolean>(false);

	const userRoles = currentUser?.role
	// console.log('curreent user ', currentUser

	useEffect(() => {
		let listFeature: UserFeatureModel[] = []
		userRoles?.map((role) => {
			listFeature = listFeature.concat(role.feature)
		})

		//  console.log('listFeature ', listFeature)
		const combinedFeatures: UserFeatureModel[] = []

		listFeature.map((originData, id) => {
			const matchedData = combinedFeatures.filter(
				(filterData) => filterData.featureName === originData.featureName
			)

			if (matchedData.length === 0) {
				combinedFeatures.push(originData)
			} else {
				const indexOf = combinedFeatures.indexOf(matchedData[0])

				matchedData.map((matchData) => {
					if (matchData.permissions.rCreate === 0 && originData.permissions.rCreate === 1) {
						matchData.permissions.rCreate = 1
					}
					if (matchData.permissions.rRead === 0 && originData.permissions.rRead === 1) {
						matchData.permissions.rRead = 1
					}

					if (matchData.permissions.rUpdate === 0 && originData.permissions.rUpdate === 1) {
						matchData.permissions.rUpdate = 1
					}

					if (matchData.permissions.rDelete === 0 && originData.permissions.rDelete === 1) {
						matchData.permissions.rDelete = 1
					}
				})
				combinedFeatures[indexOf] = matchedData[0]
			}
		})

		// console.log('combined ', combinedFeatures)
		setCurrentUserFeature(combinedFeatures)

		setIsMdDocumentVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdDocument) !==
			undefined
		)

		setIsMdVehicleVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdVehicle) !==
			undefined
		)

		setIsMdVBrandVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdVehicleBrand) !==
			undefined
		)

		setIsMdVModelVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdVehicleModel) !==
			undefined
		)

		setIsMdVTypeVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdVehicleType) !==
			undefined
		)

		setIsMdPricingVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdPricing) !==
			undefined
		)

		setIsMdPricingCatVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdPricingCategory) !==
			undefined
		)

		setIsMdPricingTypeVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdPricingType) !==
			undefined
		)

		setIsMdPricingPriceVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdPricingPrice) !==
			undefined
		)

		setIsMdCompanyVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdCompany) !==
			undefined
		)

		setIsMdDriverVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdDriver) !==
			undefined
		)

		setIsMdDriverAllowanceVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdDriverAllowance) !==
			undefined
		)

		setIsMdDriverUangJalanVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdDriverUangJajan) !==
			undefined
		)

		setIsMdAdditionalCostJalanVisible(
			combinedFeatures.find(
				(feature) => feature.featureName === FEATURE_TYPE.mdDriverAdditionalCost
			) !== undefined
		)

		setIsMdFeatureVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdFeature) !==
			undefined
		)

		setIsMdAreaVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdArea) !== undefined
		)

		setIsMdRoleVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdRole) !== undefined
		)

		setIsMdUserVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdUser) !== undefined
		)

		setIsMdEmployeeVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdEmployee) !== undefined
		)

		setIsMdClientVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdClient) !== undefined
		)

		setIsMdClientPricingVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdClientPricing) !== undefined
		)

		setIsMdInboundVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.inbound) !== undefined
		)

		setIsMdJobOrderVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdJobOrder) !== undefined
		)

		setIsEquipmentVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.Equipment) !== undefined
		)

		setIsSuratJalanVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.suratJalan) !== undefined
		)

		setIsAllowancePaymentVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.AllowancePayment) !== undefined
		)

		setIsMdPlotting(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdPlotting) !== undefined
		)

		setIsDispatchVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.dispatch) !== undefined
		)

		setIsEquipmentReturnVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.EquipmentReturn) !== undefined
		)

		//  console.log('combinedFeatures ', combinedFeatures)
		setIsInvoiceVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.invoice) !== undefined
		)

		setIsDepoVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.depo) !== undefined
		)

		setIsEquipmentOrderVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.EquipmentOrder) !== undefined
		)

		setIsEquipmentCheckVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.EquipmentCheck) !== undefined
		)

		setIsEquipmentStockVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.EquipmentStock) !== undefined
		)

		setIsMaintenanceVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.Maintenance) !== undefined
		)

		setIsStoringVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.Storing) !== undefined
		)

		setIsMdPricingUnitVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdPricingUnit) !== undefined
		)

		setIsSparepartVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.sparePart) !== undefined
		)


		setIsSparepartStockVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.SparepartStock) !== undefined
		)

		setIsCountryVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdCountry) !== undefined
		)

		setIsShipperImportirVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdShipperImportir) !== undefined
		)

		setIsVendorTypeVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdVendorType) !== undefined
		)


		setIsVendorVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdVendor) !== undefined
		)

		setIsHsCodeVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdHsCode) !== undefined
		)

		setIsProductVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdProduct) !== undefined
		)

		setIsClientPricingImpVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdClientPricingImp) !== undefined
		)

		setIsJobOrderImp(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdJobOrderImp) !== undefined
		)

		setIsUOM(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdUOM) !== undefined
		)


		// evocuher module
		setIsPpnVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdPpn) !== undefined
		)

		setIsPphVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.mdPph) !== undefined
		)

		setIsEVoucherVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.eVoucher) !== undefined
		)

		setIsInvoiceImp(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.invoiceImp) !== undefined
		)

		setIsTruckingDashboardVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.truckingDashboard) !== undefined
		)

		setIsBrokerVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.broker) !== undefined
		)

		setIsInvoiceTTDVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.invoiceTtd) !== undefined
		)
		setIsInvoiceBulkVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.invoiceBulk) !== undefined
		)

		setIsRefundUJVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.refundUJ) !== undefined
		)

		setIsInvoicePaidVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.invoicePaid) !== undefined
		)

		setIsInvoiceNotPaidVisible(
			combinedFeatures.find((feature) => feature.featureName === FEATURE_TYPE.invoiceNotPaid) !== undefined
		)

		// console.log('combinedFeatures ', combinedFeatures)
	}, [])

	return (
		<>
			<AsideMenuItem
				to='/dashboard'
				icon='/media/icons/duotune/art/art002.svg'
				title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
				fontIcon='bi-app-indicator'
			/>

			{/* Menu for MTI module starts */}
			<AsideMenuItemWithSub
				to='/crafted/pages'
				title='Master Data'
				fontIcon='bi-archive'
				icon='/media/icons/duotune/art/art002.svg'
			>
				{isMdDocumentVisible && (
					<AsideMenuItem
						to='/master/master-documents/documents'
						title='MD Documents'
						icon='/media/icons/icon-menu/doc-icon.svg'
					></AsideMenuItem>
				)}

				{isMdFeatureVisible && (
					<AsideMenuItem
						to='/master/master-feature/features'
						icon='/media/icons/duotune/art/artStar.svg'
						title={'MD Feature'}
						fontIcon='bi-app-indicator'
					/>
				)}
				{isMdCompanyVisible && (
					<AsideMenuItem
						to='/master/company/list-company'
						title='Company'
						icon='/media/icons/icon-menu/company-icon.svg'
						fontIcon='bi-app-indicator'
					/>
				)}

				{isMdEmployeeVisible && (
					<AsideMenuItem
						to='/master/employee/list-employee'
						title={'Employee'}
						icon='/media/icons/icon-menu/employee-icon.svg'
						fontIcon='bi-app-indicator'
					/>
				)}

				{isMdRoleVisible && (
					<AsideMenuItem
						to='/master/role/roles'
						icon='/media/icons/duotune/art/artRole.svg'
						title={'MD Role'}
						fontIcon='bi-app-indicator'
					/>
				)}

				{isMdUserVisible && (
					<AsideMenuItem
						to='/master/master-user/users'
						title='MD Users'
						icon='/media/icons/icon-menu/user-icon.svg'
					></AsideMenuItem>
				)}

				{(isMdVBrandVisible || isMdVModelVisible || isMdVTypeVisible || isMdVehicleVisible) && (
					<AsideMenuItemWithSub to='' title='MD Vehicle' hasBullet={true}>
						{isMdVBrandVisible && (
							<AsideMenuItem
								to='/master/master-vehicle/brands'
								title='Vehicle Brand'
								icon='/media/icons/icon-menu/vbrand-icon.svg'
							/>
						)}

						{isMdVTypeVisible && (
							<AsideMenuItem
								to='/master/master-vehicle/types'
								title='Vehicle Type'
								icon='/media/icons/icon-menu/vehicle-type-icon.svg'
							/>
						)}

						{isMdVModelVisible && (
							<AsideMenuItem
								to='/master/master-vehicle/models'
								title='Vehicle Model'
								icon='/media/icons/icon-menu/vmodel-icon.svg'
							/>
						)}

						{isMdVehicleVisible && (
							<AsideMenuItem
								to='/master/master-vehicle/vehicles'
								title='Vehicle'
								icon='/media/icons/icon-menu/vehicle-icon.svg'
							/>
						)}
					</AsideMenuItemWithSub>
				)}

				{isMdAreaVisible && (
					<AsideMenuItem
						to='/master/master-area/areas'
						icon='/media/icons/duotune/art/artArea.svg'
						title={'MD Area'}
						fontIcon='bi-app-indicator'
					/>
				)}

				{isMdDriverVisible && (
					<AsideMenuItem
						to='/master/driver/list-driver'
						title='Driver'
						icon='/media/icons/icon-menu/driver-icon.svg'
						fontIcon='bi-app-indicator'
					/>
				)}

				{isMdDriverAllowanceVisible && (
					<AsideMenuItem
						to='/master/driver/driver-allowance'
						title='Allowance'
						icon='/media/icons/icon-menu/allowance-icon.svg'
						fontIcon='bi-app-indicator'
					/>
				)}

				{isMdDriverAdditionalCostVisible && (
					<AsideMenuItem
						to='/master/driver/driver-addCost'
						title='Additional Cost'
						icon='/media/icons/icon-menu/add-cost-icon.svg'
						fontIcon='bi-app-indicator'
					/>
				)}


				{isMdDriverUangJalanVisible && (
					<AsideMenuItem
						to='/master/uang-jalan/list-uang-jalan'
						icon='/media/icons/icon-menu/uang-jalan.svg'
						title={'Uang Jalan'}
						fontIcon='bi-app-indicator'
					/>
				)}

				{(isMdPricingCatVisible ||
					isMdPricingPriceVisible ||
					isMdPricingTypeVisible ||
					isMdPricingVisible ||
					isMdPricingUnitVisible) && (
						<AsideMenuItemWithSub to='' title='MD Pricing' hasBullet={true}>
							{isMdPricingCatVisible && (
								<AsideMenuItem
									to='/master/master-pricing/categories'
									title='Kategori'
									icon='/media/icons/icon-menu/pricing-category-icon.svg'
								/>
							)}
							{isMdPricingTypeVisible && (
								<AsideMenuItem to='/master/master-pricing/types' title='Tipe' icon='/media/icons/icon-menu/pricing-type-icon.svg' />
							)}
							{isMdPricingUnitVisible && (
								<AsideMenuItem to='/master/master-pricing/units' title='Unit' icon='/media/icons/icon-menu/pricing-icon.svg' />
							)}
							{isMdPricingVisible && (
								<AsideMenuItem to='/master/master-pricing/prices' title='Price' icon='/media/icons/icon-menu/pricing-icon.svg' />
							)}

						</AsideMenuItemWithSub>
					)}

				{isBrokerVisible && (
					<AsideMenuItem
						to='/master/master-broker/broker-list'
						icon='/media/icons/icon-menu/client-icon.svg'
						title={'Broker'}
						fontIcon='bi-app-indicator'
					/>
				)}

				{isMdClientVisible && (
					<AsideMenuItem
						to='/master/client/list-client'
						icon='/media/icons/icon-menu/client-icon.svg'
						title={'Client'}
						fontIcon='bi-app-indicator'
					/>
				)}

				{isMdClientPricingVisible && (
					<AsideMenuItem
						to='/master/client-pricing/list-client-pricing'
						icon='/media/icons/icon-menu/pricing-client.svg'
						title={'Client Pricing'}
						fontIcon='bi-app-indicator'
					/>
				)}

				{isDepoVisible && (
					<AsideMenuItem
						to='/master/depo/list-depo'
						icon='/media/icons/icon-menu/depo-icon.svg'
						title={'Depo'}
						fontIcon='bi-app-indicator'
					/>
				)}
			</AsideMenuItemWithSub>

			{(isMdJobOrderVisible ||
				isMdPlotting ||
				isAllowancePaymentVisible ||
				isEVoucherVisible ||
				isSuratJalanVisible ||
				isDispatchVisible ||
				isInvoiceVisible || isTruckingDashboardVisible || isInvoiceTTDVisible || isInvoiceBulkVisible || isRefundUJVisible || isInvoicePaidVisible || isInvoiceNotPaidVisible) && (
					<AsideMenuItemWithSub
						to='/crafted/pages'
						title='Transaction'
						fontIcon='bi-archive'
						icon='/media/icons/duotune/art/art002.svg'
					>
						{isTruckingDashboardVisible && (
							<AsideMenuItem
								to='/trucking/dashboard'
								title='Trucking Dashboard'
								icon='/media/icons/duotune/art/art002.svg'
							></AsideMenuItem>
						)}

						{isMdJobOrderVisible && (
							<AsideMenuItem
								to='/master/master-job-orders/joborders'
								title='Job Order'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}
							<AsideMenuItem
								to='/transaction/depo/depo-list'
								title='Depo Submission'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>

						{isMdPlotting && (
							<AsideMenuItem
								to='/master/plotting/list-plotting'
								title='Plotting'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isAllowancePaymentVisible && (
							<AsideMenuItem
								to='/transaction/allowance-payment/list-payments'
								title='Transfer UJ'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{/* {isEVoucherVisible && (
							<AsideMenuItem
								to='/master/master-evoucher/evoucher/list'
								title='E-Voucher'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)} */}

						{isSuratJalanVisible && (
							<AsideMenuItem
								to='/transaction/surat-jalan/list-surat-jalan'
								icon='/media/icons/icon-menu/doc-icon.svg'
								title={'Surat Jalan'}
								fontIcon='bi-app-indicator'
							/>
						)}

						{isDispatchVisible && (
							<AsideMenuItem
								to='/transaction/dispatch/list-dispatch'
								icon='/media/icons/duotune/art/artRole.svg'
								title={'Dispatch'}
								fontIcon='bi-app-indicator'
							/>
						)}

						{isRefundUJVisible && (
							<AsideMenuItem
								to='/transaction/refund/list'
								icon='/media/icons/icon-menu/doc-icon.svg'
								title={'Refund UJ'}
								fontIcon='bi-app-indicator'
							/>
						)}

						{isInvoiceVisible && (
							<AsideMenuItem
								to='/transaction/invoice/list-invoice'
								icon='/media/icons/icon-menu/doc-icon.svg'
								title={'Invoice'}
								fontIcon='bi-app-indicator'
							/>
						)}

						{isInvoiceTTDVisible && (
							<AsideMenuItem
								to='/transaction/invoice-ttd/list-invoice'
								title='Invoice TTD'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}
						{isInvoiceBulkVisible && (
							<AsideMenuItem
								to='/transaction/invoice-bulk/list-invoice'
								title='Invoice Bulk'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}
						{isInvoicePaidVisible && (
							<AsideMenuItem
								to='/transaction/invoice-status/list-paid'
								title='Invoice Paid'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}
						{isInvoiceNotPaidVisible && (
							<AsideMenuItem
								to='/transaction/invoice-status/list-unpaid'
								title='Invoice Not Paid'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}
					</AsideMenuItemWithSub>
				)}

			{(isEquipmentVisible ||
				isMdInboundVisible ||
				isEquipmentOrderVisible ||
				isEquipmentCheckVisible ||
				isEquipmentReturnVisible ||
				isEquipmentStockVisible ||
				isMaintenanceVisible ||
				isStoringVisible ||
				isSparepartVisible ||
				isSparepartStockVisible) && (

					<AsideMenuItemWithSub
						to='/crafted/pages'
						title='Maintenance'
						fontIcon='bi-archive'
						icon='/media/icons/duotune/art/art002.svg'
					>


						{isEquipmentVisible && (
							<AsideMenuItem
								to='/master/master-equipment/equipments'
								title='Equipment'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isMdInboundVisible && (
							<AsideMenuItem
								to='/transaction/inbound/list-inbound'
								title={'Inbound'}
								icon='/media/icons/icon-menu/doc-icon.svg'
								fontIcon='bi-app-indicator'
							/>
						)}

						{isEquipmentOrderVisible && (
							<AsideMenuItem
								to='/transaction/equipment-order/list-equipment-order'
								title='Equipment Order'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isEquipmentCheckVisible && (
							<AsideMenuItem
								to='/transaction/equipment-check/list-equipment-check'
								title='Equipment Check'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isEquipmentReturnVisible && (
							<AsideMenuItem
								to='/transaction/equipment-return/list-equipment-return'
								title='Equipment Return'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isEquipmentStockVisible && (
							<AsideMenuItem
								to='/transaction/equipment-stock/list-equipment-stock'
								title='Equipment Stock'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isMaintenanceVisible && (
							<AsideMenuItem
								to='/transaction/maintenance/list-maintenance'
								title='Maintenance'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isStoringVisible && (
							<AsideMenuItem
								to='/transaction/storing/list-storing'
								title='Storing'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{/* {isSparepartVisible && (
							<AsideMenuItem
								to='/master/master-sparepart/spareparts'
								title='Sparepart'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)} */}

						{/* {isSparepartStockVisible && (
							<AsideMenuItem
								to='/transaction/sparepart-stock/list-sparepart-stock'
								title='Sparepart Stock'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)} */}

					</AsideMenuItemWithSub>

				)}
			{/* Menu for MTI module end */}

			{/* Menu for importir module starts */}
			{(isCountryVisible || isShipperImportirVisible ||
				isVendorTypeVisible || isVendorVisible ||
				isHsCodeVisible || isUOM ||
				isProductVisible || isClientPricingImpVisible) && (

					<AsideMenuItemWithSub
						to='/crafted/pages'
						title='Master Data Importir'
						fontIcon='bi-archive'
						icon='/media/icons/duotune/art/art002.svg'
					>

						{isCountryVisible && (
							<AsideMenuItem
								to='/master/master-countries/countries'
								title='MD Country'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isShipperImportirVisible && (
							<AsideMenuItem
								to='/master/master-shipper-importir/list-shippers'
								title='MD Shiper Importir'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{(isVendorTypeVisible || isVendorVisible) && (
							<AsideMenuItemWithSub to='' title='MD Vendor' hasBullet={true}>
								{isVendorTypeVisible && (
									<AsideMenuItem
										to='/master/master-vendor-type/list-types'
										title='Vendor Type'
										icon='/media/icons/icon-menu/vbrand-icon.svg'
									/>
								)}
								{isVendorVisible && (
									<AsideMenuItem
										to='/master/master-vendor/list-vendors'
										title='Vendor'
										icon='/media/icons/icon-menu/vbrand-icon.svg'
									/>
								)}


							</AsideMenuItemWithSub>
						)}

						{isHsCodeVisible && (
							<AsideMenuItem
								to='/master/master-hscode/list-hscode'
								title='MD HS CODE'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isUOM && (
							<AsideMenuItem
								to='/master/master-uom/uom'
								title='MD OUM'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isProductVisible && (
							<AsideMenuItem
								to='/master/master-product/list-products'
								title='MD Product'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

						{isClientPricingImpVisible && (
							<AsideMenuItem
								to='/master/master-client-pricing-imp/list-client-pricing'
								title='MD Client Pricing Importir'
								icon='/media/icons/icon-menu/doc-icon.svg'
							></AsideMenuItem>
						)}

					</AsideMenuItemWithSub>
				)}

			{(isJobOrderImp || isInvoiceImp) && (

				<AsideMenuItemWithSub
					to='/crafted/pages'
					title='Transaction Importir'
					fontIcon='bi-archive'
					icon='/media/icons/duotune/art/art002.svg'
				>

					{isJobOrderImp && (
						<AsideMenuItem
							to='/master/master-job-order-imp/list-job-order-imp'
							title='Job Order Importir'
							icon='/media/icons/icon-menu/doc-icon.svg'
						></AsideMenuItem>
					)}

					{isInvoiceImp && (
						<AsideMenuItem
							to='/transaction/invoice-imp/list-invoice'
							title='Invoice Importir'
							icon='/media/icons/icon-menu/doc-icon.svg'
						></AsideMenuItem>
					)}

				</AsideMenuItemWithSub>
			)}
			{/* Menu for importir module end */}

			{/* Menu for e-voucher module start */}
			{(isPpnVisible || isPphVisible) && (
				<AsideMenuItemWithSub
					to='/crafted/pages'
					title='Master Data e-Voucher'
					fontIcon='bi-archive'
					icon='/media/icons/duotune/art/art002.svg'
				>

					{isPpnVisible && (
						<AsideMenuItem
							to='/master/master-ppn/list-ppn'
							title='MD PPN'
							icon='/media/icons/icon-menu/doc-icon.svg'
						></AsideMenuItem>
					)}

					{isPphVisible && (
						<AsideMenuItem
							to='/master/master-pph/list-pph'
							title='MD PPH'
							icon='/media/icons/icon-menu/doc-icon.svg'
						></AsideMenuItem>
					)}


				</AsideMenuItemWithSub>
			)}

			{isEVoucherVisible && (
				<AsideMenuItem
					to='/master/master-evoucher/evoucher/list'
					icon='/media/icons/icon-menu/doc-icon.svg'
					title='E-Voucher'
					fontIcon='bi-app-indicator'
				/>
			)}
			{/* Menu for e-voucher module end */}
		</>
	)
}
