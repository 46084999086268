import {FC, useState} from 'react'
import { ButtonProps } from './button.prop'


/**
 * For your text displaying needs.
 *
 * This component is a HOC over the built-in React Native one.
 */
export function Buttons(props: ButtonProps) {
  // grab the props
  const {
    // preset = "primary",
    // tx,
    text,
    style: styleOverride,
    textStyle: textStyleOverride,
    children,
    type,
    onClickButton,
    isDisabled,
    ...rest
  } = props

  // const viewStyle = viewPresets[preset] || viewPresets.primary
  // const viewStyles = [viewStyle, styleOverride]
  // const textStyle = textPresets[preset] || textPresets.primary
  // const textStyles = [textStyle, textStyleOverride]

  const content = children || null

  if (type === 'primary') {
    return (
      <button
        type='submit'
        className='btn btn-primary'
        data-kt-users-modal-action='Simpan'
        onClick={onClickButton}
        disabled={isDisabled || false}
      >
        <span className='indicator-label'>{text}</span>
      </button>
    )
  }

  if (type === 'danger') {
    return (
      <button
        className='btn btn-danger'
        data-kt-users-modal-action='Close'
        onClick={onClickButton}
        disabled={isDisabled || false}
      >
        <span className='indicator-label'>{text}</span>
      </button>
    )
  }

  if (type === 'secondary') {
    return (
      <button
        // type='submit'
        className={'btn btn-light me-3'}
        data-kt-users-modal-action='Close'
        onClick={onClickButton}
        // style={{}}
        style={{
          cursor: 'pointer',
          ...styleOverride,
        }}
        disabled={isDisabled || false}
      >
        <span className='indicator-label'>{text}</span>
      </button>
    )
  }

  // if (type === 'primary-dark') {
  //   return (
  //     <TouchableOpacity
  //       style={[
  //         {
  //           backgroundColor: Colors.UNDERTONE_BLUE,
  //           paddingVertical: Spacing[8],
  //           borderRadius: Spacing[20],
  //         },
  //         styleOverride,
  //       ]}
  //       {...rest}
  //     >
  //       <Text
  //         type={'button'}
  //         tx={tx}
  //         text={text}
  //         style={[
  //           {
  //             textAlign: 'center',
  //             color: Colors.WHITE,
  //             fontSize: Spacing[14],
  //           },
  //           textStyleOverride,
  //         ]}
  //       />
  //     </TouchableOpacity>
  //   )
  // }

  // if (type === 'secondary') {
  //   return (
  //     <TouchableOpacity
  //       style={[
  //         {
  //           paddingVertical: Spacing[8],
  //         },
  //         styleOverride,
  //       ]}
  //       {...rest}
  //     >
  //       <Text
  //         type={'button'}
  //         tx={tx}
  //         text={text}
  //         style={[
  //           {
  //             textAlign: 'center',
  //             color: Colors.UNDERTONE_BLUE,
  //             fontSize: Spacing[14],
  //           },
  //           textStyleOverride,
  //         ]}
  //       />
  //     </TouchableOpacity>
  //   )
  // }

  // if (type === 'negative') {
  //   return (
  //     <TouchableOpacity
  //       style={[
  //         {
  //           backgroundColor: Colors.CLOUD_GRAY,
  //           paddingVertical: Spacing[8],
  //           paddingHorizontal: Spacing[16],
  //           borderRadius: Spacing[20],
  //         },
  //         styleOverride,
  //       ]}
  //       {...rest}
  //     >
  //       <Text
  //         type={'button'}
  //         tx={tx}
  //         text={text}
  //         style={[
  //           {
  //             textAlign: 'center',
  //             color: Colors.UNDERTONE_BLUE,
  //             fontSize: Spacing[14],
  //           },
  //           textStyleOverride,
  //         ]}
  //       />
  //     </TouchableOpacity>
  //   )
  // }

  // if (type === 'warning') {
  //   return (
  //     <TouchableOpacity
  //       style={[
  //         {
  //           backgroundColor: Colors.MAIN_RED,
  //           paddingVertical: Spacing[8],
  //           borderRadius: Spacing[20],
  //         },
  //         styleOverride,
  //       ]}
  //       {...rest}
  //     >
  //       <Text
  //         type={'button'}
  //         tx={tx}
  //         text={text}
  //         style={[
  //           {
  //             textAlign: 'center',
  //             color: Colors.WHITE,
  //             fontSize: Spacing[14],
  //           },
  //           textStyleOverride,
  //         ]}
  //       />
  //     </TouchableOpacity>
  //   )
  // }

  // if (type === 'transparent') {
  //   return (
  //     <TouchableOpacity
  //       style={[
  //         {
  //           // backgroundColor: Colors.WH,
  //           paddingVertical: Spacing[8],
  //           borderRadius: Spacing[20],
  //         },
  //         styleOverride,
  //       ]}
  //       {...rest}
  //     >
  //       <Text
  //         type={'button'}
  //         tx={tx}
  //         text={text}
  //         style={[
  //           {
  //             textAlign: 'center',
  //             color: Colors.MAIN_BLUE,
  //             fontSize: Spacing[14],
  //           },
  //           textStyleOverride,
  //         ]}
  //       />
  //     </TouchableOpacity>
  //   )
  // }

  return <button {...rest}>{content}</button>
}
