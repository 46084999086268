import customAxios from './custom-axios'

const INVOICE_TTD_URL = process.env.REACT_APP_DEV_API + `/invoice-bulk`

// const getInvoiceTTD = async (query: string) => {
//   return customAxios.get(`${INVOICE_TTD_URL}/get?${query}`).then((d) => d?.data)
// }

const addInvoiceBulk = async (form: {invoices: string[], invDoctype: string}) => {
  return customAxios.put(`${INVOICE_TTD_URL}/update`, form).then((res) => res?.data)
}

const uploadBulkDocs = async (form: FormData, id: number) => {
  return customAxios.post(`${INVOICE_TTD_URL}/doc/${id}?type=bulk`, form).then((res) => res)
}

const updateBulkStatus = async(form: {invoices: string[], invDoctype: string, paymentStatus: string}) => {
  return customAxios.put(`${INVOICE_TTD_URL}/update`, form).then((res) => res)
}

export {addInvoiceBulk, uploadBulkDocs, updateBulkStatus}