/* eslint-disable react-hooks/exhaustive-deps */
import {FC, useContext, useState, useEffect, useMemo} from 'react'
import {useQuery} from 'react-query'
import {
  createResponseContext,
  initialQueryResponse,
  initialQueryState,
  PaginationState,
  QUERIES,
  stringifyRequestQuery,
} from '../../../_metronic/helpers'
import {getJobOrders} from '../../api/job-order-api'
import {JobOrder} from '../../models/job-order-model'
import {useQueryRequest} from './QueryRequestProvider'
import { useAuth } from 'src/app/context/auth/AuthProvider'

const QueryResponseContext = createResponseContext<JobOrder>(initialQueryResponse)
const QueryResponseProvider: FC = ({children}) => {
  const {state} = useQueryRequest()
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

	const { currentUser } = useAuth()
	let companyIdUser = '';
	if (currentUser?.employee.company.companyName === 'comp1') {
		if (window.location.hostname !== 'localhost') {
			companyIdUser = 'e29d29dc-cbce-43bd-a065-e4a9ab633892';
		}
		else {
			companyIdUser = 'e29d29dc-cbce-43bd-a065-e4a9ab633892';
		}
	}
	else {

		if (currentUser?.employee.company.id) {
			companyIdUser = currentUser?.employee.company.id
		}

	}

  useEffect(() => {
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
  }, [updatedQuery])

  const {
    isFetching,
    refetch,
    data: response,
  } = useQuery(
    `${QUERIES.USERS_LIST}-${query}`,
    () => {
		return getJobOrders(query, companyIdUser)
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

//   alert(isFetching)
  return (
    <QueryResponseContext.Provider value={{isLoading: isFetching, refetch, response, query}}>
      {children}
    </QueryResponseContext.Provider>
  )
}

const useQueryResponse = () => useContext(QueryResponseContext)

// const useQueryResponseData = () => {
//   const {response} = useQueryResponse()
//   if (!response) {
//     return []
//   }

//   return response?.data || []
// }

const useQueryResponseData = () => {
	const { response } = useQueryResponse()
	if (!response) {
		return []
	}

	let newData: JobOrder[] = []
	console.log('originData ', response?.data)
	if (response?.data) {
		newData = response?.data.map((jobroderData, id) => ({ ...jobroderData, no: id + 1 }))

		console.log('updatedData ', newData)
	}

	return newData || []
}


const useQueryResponsePagination = () => {
	const { state } = useQueryRequest()
	const defaultPaginationState: PaginationState = {
		links: [],
		...initialQueryState,
	}
	const currPage = state.page
	const currLimit = state.limit


	const { response } = useQueryResponse()

	if (!response || !response.count) {
		return defaultPaginationState
	}


	let tempLastPage = 0
	let tempRemaining = response.count % currLimit
	if (tempRemaining === 0) {
		tempLastPage = response.count / currLimit
	} else {
		let tempNewCount = response.count - tempRemaining
		tempLastPage = tempNewCount / currLimit
		tempLastPage = tempLastPage + 1
	}

	let previousPage = 1;
	if (currPage !== 1 && tempLastPage > 0) {
		previousPage = currPage - 1
	}

	let nextPage = 1;
	if (currPage < tempLastPage) {
		nextPage = currPage + 1
	} else {
		nextPage = currPage
	}
	// console.log('currPage ', currPage)
	// console.log('previousPage ', previousPage)
	// console.log('nextPage ', nextPage)


	let tempLink = []
	tempLink.push({
		"url": null,
		"label": "&laquo; Previous",
		"active": false,
		"page": previousPage
	})
	for (let i = 1; i <= tempLastPage; i++) {
		tempLink.push({
			"url": `/?page=${i}`,
			"label": i.toString(),
			"active": i === currPage,
			"page": i
		})
	}
	tempLink.push({
		"url": `/?page=${currPage + 1}`,
		"label": "Next &raquo;",
		"active": false,
		"page": nextPage
	})

	// console.log('tempLink ', tempLink)
	let tempPagination = {
		"page": currPage,
		"first_page_url": "/?page=1",
		"from": 1,
		"last_page": tempLastPage,
		"links": tempLink,
		"next_page_url": "/?page=2",
		"limit": currLimit,
		"prev_page_url": null,
		"to": currLimit,
		"total": response.count
	}

	return tempPagination
}

const useQueryResponseLoading = (): boolean => {
  const {isLoading} = useQueryResponse()
  return isLoading
}

export {
  QueryResponseProvider,
  useQueryResponse,
  useQueryResponseData,
  useQueryResponsePagination,
  useQueryResponseLoading,
}
