/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import React, {FC} from 'react'
import {KTSVG} from '../../../helpers'
import {useLayout} from '../../core'
import {DefaultTitle} from '../header/page-title/DefaultTitle'
import { PlottingsListFilter } from 'src/app/modules/transaction/plotting/plottings-list/components/header/PlottingsListFilter'

export type ToolbarProps = {
  title?: string
  buttonText?: string
  buttonVisible: boolean
  onClickButton(): void
}

function Toolbar1(props: ToolbarProps) {
  const {classes} = useLayout()

  const {title, buttonText, buttonVisible, onClickButton} = props

  return (
    <div className='toolbar' id='kt_toolbar'>
      {/* begin::Container */}
      <div
        id='kt_toolbar_container'
        className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
      >
        <h2>
          <b>{title}</b>
        </h2>

        {/* begin::Actions */}
        <div className='d-flex align-items-center py-1'>
          {/* begin::Wrapper */}
          <div className='me-4'>
            {/* begin::Menu */}

			{(() => {
				if(title === 'Data Plotting')
				{
					return(
						<>
							<PlottingsListFilter/>
						</>
					)
				}
			})()}

            {/* <a
              href='#'
              className='btn btn-sm btn-flex btn-light btn-active-primary fw-bolder'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              <KTSVG
                path='/media/icons/duotune/general/gen031.svg'
                className='svg-icon-5 svg-icon-gray-500 me-1'
              />
              Filter
            </a> */}
          </div>
          {buttonVisible && (
            <button type='button' className='btn btn-primary btn-sm' onClick={onClickButton}>
              {buttonText}
            </button>
          )}

          {/* end::Button */}
        </div>
        {/* end::Actions */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Toolbar1}
