import { KTCard } from 'src/_metronic/helpers';
import { Toolbar } from 'src/_metronic/layout/components/toolbar/Toolbar'
import { Toolbar1 } from 'src/_metronic/layout/components/toolbar/Toolbar1'

import { useNavigate } from "react-router-dom"
import InvoiceBulkTable from './table/InvoiceBulkTable';
const InvoiceBulkList = () => {
	const navigate = useNavigate()
  return (
    <div>
      <KTCard>
        <Toolbar
          type={''}
          children={
            <Toolbar1
              buttonVisible={true}
              onClickButton={() => navigate('../create-bulk')}
              buttonText='Create'
              title='Invoice Bulk Page'
            />
          }
        ></Toolbar>
        <InvoiceBulkTable />
      </KTCard>
    </div>
  )
}


export default InvoiceBulkList