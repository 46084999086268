import {useCallback, useEffect, useState} from 'react'

import clsx from 'clsx'
import { useLayout } from '../../../../../../_metronic/layout/core'

import Select from 'react-select'
import { Buttons } from 'src/_metronic/layout/components/button/Button'
import { useNavigate } from "react-router-dom"
import { getInvoice } from 'src/app/api/invoice-api'
import dateFormat from 'dateformat'
import { addInvoiceBulk } from 'src/app/api/invoice-bulk-api'


const InvoiceBulkCreate = () => {
    // data prep
    const navigate = useNavigate()
    const { classes } = useLayout()
    const [loading, setLoading] = useState<boolean>(false);
    const [invoiceData, setInvoiceData] = useState<any>([]);
    const [currLimit, setCurrLimit] = useState<number>(10)
    const [selectedInvoices, setSelectedInvoices] = useState<string[]>([])

    const handleSubmitForm = useCallback(async () => {
      try {
        const submitData = {
          invoices: selectedInvoices,
          invDoctype: 'bulk-invoice'
        }
        const res = await addInvoiceBulk(submitData)
        if(res){
          // success
          alert('success')
          navigate("../list-invoice")
        }
        else{
          alert('Failed')
        }
        
      } catch (error) {
        alert('Failed')
      }
      finally{
        setLoading(false)
      }
      

    }, [navigate, selectedInvoices])

    const handleSelectInvoice = (invNo: string) => {
      const isFind = selectedInvoices.findIndex((value) => value === invNo)
      if(isFind === -1) {
        setSelectedInvoices((selected) => [
          ...selected,
          invNo
        ])
      }
      else{
        setSelectedInvoices((selected) => {
          const newArr = selected
          newArr.splice(isFind, 1)
          return newArr
        })
      }
    }

    const GetInvoices = useCallback(async () => {
      const resultInvoice = await getInvoice(`limit=${currLimit}&offset=0&isPaid=1&isNotBulk=true`)
      
      setInvoiceData(resultInvoice?.data)
    }, [currLimit])

    const currencyFormatter = (val: number) => {
      return new Intl.NumberFormat("id-ID", {
        style: "currency",
        currency: "IDR",
        maximumFractionDigits: 0
      }).format(val)
    }

    useEffect(() => {
      GetInvoices()
    },[GetInvoices])
    return (
      <>
        <div className='card mb-5 mb-xl-10'>
          <div className='toolbar' id='kt_toolbar'>
            <div
              id='kt_toolbar_container'
              className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
            >
              <h2>
                <b>Create Invoice Bulk Payment</b>
              </h2>
            </div>
          </div>
          <div className='card-body'>
            <div className='row mb-10'>
              <label className='col-lg-1 col-form-label text-lg-start'>No.</label>
              <label className='col-lg-3 col-form-label text-lg-start'>Invoice No.</label>
              <label className='col-lg-3 col-form-label text-lg-start'>Invoice Date</label>
              <label className='col-lg-3 col-form-label text-lg-start'>Amount</label>
              <label className='col-lg-2 col-form-label text-lg-center'>Select</label>
            </div>

            {invoiceData.map((value: any, index: number) => {
              return (
                <div className='row mb-10' key={index}>
                  <p className='col-lg-1'>{index + 1}.</p>
                  <p className='col-lg-3'>{value?.invNo}</p>
                  <p className='col-lg-3'>{dateFormat(value?.invDate, 'dd-mmm-yyyy')}</p>
                  <p className='col-lg-3'>{currencyFormatter(Number(value?.invTotal))}</p>
                  <div className='col-lg-2 text-lg-center'>
                    <input type='checkbox' id='is_commission' className='form-check-input' onChange={() => {handleSelectInvoice(value?.id)}}/>
                  </div>
                </div>
              )
            })}

            {(invoiceData.length > 0 && invoiceData.length % 10 === 0) && 
              <div className='row mb-10'>
                <div className="col-lg-3 mx-auto text-center">
                  <Buttons
                    type='secondary'
                    text={'Show More'}
                    onClickButton={() => {
                      setCurrLimit(currLimit+10)
                    }}
                  ></Buttons>
                </div>
              </div>
            }

            <div className='card mb-5 mb-xl-10 row'>
              <div className='text-end p-5'>
                <Buttons
                  type='secondary'
                  text={'Cancel'}
                  onClickButton={() => {
                    navigate('../list-invoice')
                  }}
                ></Buttons>
                <Buttons
                  type='primary'
                  text={'Submit'}
                  onClickButton={handleSubmitForm}
                  loading={true}
                  isDisabled={loading}
                ></Buttons>
              </div>
            </div>
          </div>
        </div>
      </>
    )
}

export default InvoiceBulkCreate