/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { KTSVG, toAbsoluteUrl } from '../../../helpers'
import { getRealisasi } from 'src/app/api/evoucher-realisasi-api'

type Props = {
  className: string
  color: string
}

const MixedWidgetRealisation: React.FC<Props> = ({ className, color }) => {

  const [result, setResult] = useState({})
  const [totalRealisasi, setTotalRealisasi] = useState(0)
  const [summary, setSummary] = useState({totalRealisationsAmount:0, groupedRealisationByStatus:{
    'Realisasi Approved': {count:0, total_amount:0},
    'Realisasi waiting FD' : {count:0, total_amount:0},
    'Realisasi waiting FH':{count:0, total_amount:0},
    'Realisasi waiting checker':{count:0, total_amount:0},
    'Realisasi check':{count:0, total_amount:0}
  },realisasiTypeCount:{Job:{count:0,amount:0},NonJob:{count:0,amount:0}}})

  function currencyFormat(num: number) {
    if (num !== undefined) {
      return 'Rp. ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      return 0
    }
  }

  useEffect(() => {
    const getData = async() => {
      const res = await getRealisasi()
      setTotalRealisasi(res.count)
      setSummary(res?.summary)
    }

    getData()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bolder fs-3'>Realisation Summary</h3>
            <div className='ms-1'>
              {/* begin::Menu */}
              <button
                type='button'
                className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
              </button>
              <Dropdown1 />
              {/* end::Menu */}
            </div>
          </div>

          <div className='row g-5 g-xl-12'>
            {/*  */}
            <div className='col-xl-6'>
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span className='fw-bold fs-7'>Realisation Total</span>
                <span className='fw-bolder fs-2x pt-1'>{totalRealisasi}</span>
              </div>
            </div>

            <div className='col-xl-6'>
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span className='fw-bold fs-7'>Realisation Amount Total</span>
                <span className='fw-bolder fs-2x pt-1'>{currencyFormat(summary?.totalRealisationsAmount)}</span>
              </div>
            </div>
          </div>

          {/* job type start */}

          <div className='row g-5 g-xl-12'>
            {/*  */}
            <div className='col-xl-6'>
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span className='fw-bold fs-7'>Total Job</span>
                <span className='fw-bolder fs-2x pt-1'>{summary?.realisasiTypeCount?.Job?.count}</span>
                <span className='fw-bolder fs-5 pt-1'>{summary?.realisasiTypeCount?.Job?.amount === 0 ? 'Rp. 0' : currencyFormat(summary?.realisasiTypeCount?.Job?.amount)}</span>
              </div>
            </div>

            <div className='col-xl-6'>
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span className='fw-bold fs-7'>Total Non-Job</span>
                <span className='fw-bolder fs-2x pt-1'>{summary?.realisasiTypeCount?.NonJob?.count}</span>
                <span className='fw-bolder fs-5 pt-1'>{summary?.realisasiTypeCount?.NonJob?.amount === 0 ? '0' : currencyFormat(summary?.realisasiTypeCount?.NonJob?.amount)}</span>
              </div>
            </div>
          </div>

        </div>
        {/* end::Header */}


        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
          style={{ marginTop: '-40px' }}
        >
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='ps-4 min-w-125px rounded-start'>Status</th>
                  <th className='min-w-125px'>Realisation</th>
                  <th className='min-w-150px'>Amount</th>
                </tr>
              </thead>
              {/* end::Table head */}

              {/* begin::Table body */}
              <tbody>

                <tr>
                  <td>
                    <span className='badge badge-light-primary fs-7 fw-bold'>Realisasi Waiting Division Head</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{summary?.groupedRealisationByStatus['Realisasi check']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(summary?.groupedRealisationByStatus['Realisasi check']?.total_amount)}</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className='badge badge-light-danger fs-7 fw-bold'>Realisasi Waiting Checker</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{summary?.groupedRealisationByStatus['Realisasi waiting checker']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(summary?.groupedRealisationByStatus['Realisasi waiting checker']?.total_amount)}</a>
                  </td>
                </tr>
 
                <tr>
                  <td>
                    <span className='badge badge-light-info fs-7 fw-bold'>Realisasi Waiting Finance Head</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{summary?.groupedRealisationByStatus['Realisasi waiting FH']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(summary?.groupedRealisationByStatus['Realisasi waiting FH']?.total_amount)}</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className='badge badge-light-warning fs-7 fw-bold'>Realisasi Waiting Finance Director</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{summary?.groupedRealisationByStatus['Realisasi waiting FD']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(summary?.groupedRealisationByStatus['Realisasi waiting FD']?.total_amount)}</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className='badge badge-light-danger fs-7 fw-bold'>Realisation Aproved</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{summary?.groupedRealisationByStatus['Realisasi Approved']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(summary?.groupedRealisationByStatus['Realisasi Approved']?.total_amount)}</a>
                  </td>
                </tr> 
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MixedWidgetRealisation }
