import React, {FC} from 'react'
import { useAuth } from 'src/app/context/auth/AuthProvider';
import {MenuInner} from './MenuInner'

const current = new Date();
const arr_hari = ['', 'Senin', 'Selasa', 'Rabu', 'Kamis', "Jum'at", 'Sabtu', 'Minggu'];
const hari = arr_hari[current.getDay()];
const date = `${hari}, ${current.getDate()}-${current.getMonth()+1}-${current.getFullYear()}`;

const Header: FC = () => {
  const {currentUser} = useAuth()

  return (
    <div
      className='header-menu align-items-stretch'
      data-kt-drawer='true'
      data-kt-drawer-name='header-menu'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='end'
      data-kt-drawer-toggle='#kt_header_menu_mobile_toggle'
      data-kt-swapper='true'
      data-kt-swapper-mode='prepend'
      data-kt-swapper-parent="{default: '#kt_body', lg: '#kt_header_nav'}"
    >
      <div
        className='menu menu-lg-rounded menu-column menu-lg-row menu-state-bg menu-title-gray-700 menu-state-title-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-400 fw-bold my-5 my-lg-0 align-items-stretch'
        id='#kt_header_menu'
        data-kt-menu='true'
      >
        <div>
          <h1 style={{marginTop: '10px' }}>Selamat Datang {currentUser?.employee.name}</h1>
          <small style={{ color: '#a1a5b7' }}> {date}</small>
        </div>
        {/* <MenuInner /> */}
      </div>
    </div>
  )
}

export {Header}
