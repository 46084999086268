import { FC, useState, createContext, useContext, useMemo } from 'react'
import {
  ID,
  calculatedGroupingIsDisabled,
  calculateIsAllDataSelected,
  groupingOnSelect,
  groupingOnSelectAll,
  initialPermission,
  Permission,
  ListEVoucherContextProps,
  initialListEVoucher,
} from 'src/_metronic/helpers'
import { useQueryResponseEVoucher, useQueryResponseEVoucherData } from './QueryResponseProvider'
import { EVoucher, initialData } from 'src/app/models/evoucher-model'

const ListEVoucherContext = createContext<ListEVoucherContextProps>(initialListEVoucher)

const ListEVoucherProvider: FC = ({ children }) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListEVoucher.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListEVoucher.itemIdForUpdate)
  const [permission, setPermission] = useState<Permission>(initialPermission)
  const { isLoading } = useQueryResponseEVoucher()
  const data = useQueryResponseEVoucherData()
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  const [currentEVoucher, setCurrentEVoucher] = useState<EVoucher>(initialData)
  const [selectedDataIndex, setSelectedDataIndex] = useState<number>(0)
  const [itemIdForViewDoc, setItemIdForViewDoc] = useState<ID>(undefined)

  // console.log('data ', data)
  return (
    <ListEVoucherContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        permission,
        setPermission,
        itemIdForViewDoc,
        setItemIdForViewDoc,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
        selectedDataIndex,
        setSelectedDataIndex,
      }}
    >
      {children}
    </ListEVoucherContext.Provider>
  )
}

const useListEVoucherView = () => useContext(ListEVoucherContext)

export { ListEVoucherProvider, useListEVoucherView }
