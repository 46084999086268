import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
import { getInvoiceTTD } from 'src/app/api/invoice-ttd-api'
import dateFormat from 'dateformat'

const InvoiceTTDPDF = async (id: string) => {
  function currencyFormat(num: number) {
    if (num !== undefined) {
      return 'IDR ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      return ''
    }
  }

  let query: any = new URLSearchParams({
    limit: String(9999),
    offset: String(0),
    type: "ttd",
    ttd_id: id,
  }).toString()
  const resData = await getInvoiceTTD(query)

  const doc = new jsPDF()

  if (resData.status === 200) {
    // set data here

    const data = resData?.data

    const ttdNo = `INV-TTD-${data[0]?.inv?.invDetail[0]?.client?.clientCode}-${dateFormat(data[0]?.created_at, 'mmdd')}${data[0]?.ttdId}`
    const createDate = data[0]?.created_at ? dateFormat(data[0]?.created_at, "mmm dd, yyyy") : ''
    const clientName = data[0]?.inv?.invDetail[0]?.client?.clientName ? data[0]?.inv?.invDetail[0]?.client?.clientName : ''
    const clientAddress = data[0]?.inv?.invDetail[0]?.client?.clientAddress ? data[0]?.inv?.invDetail[0]?.client?.clientAddress : ''
    let newData: any[] = []

    // newData = data.map((value: any, id: number) => ({
    //   no: id + 1,
    //   desc: value.v_description,
    //   ppn: currencyFormat(parseInt(value.ppn_amount)),
    //   pph: currencyFormat(parseInt(value.pph_amount)),
    //   amount: currencyFormat(parseInt(value.vAmount)),
    // }))

    data.forEach((value: any, id: number) => {
      newData.push({
        no: id + 1,
        desc: `Invoice ${value?.inv?.invDetail[0]?.client?.broker?.name || "PT. MERASETI TRANSPORTASI INDONESIA"} nomor ${value?.inv?.invNo}`,
        sheet: '1',
        check1: '',
        check2: '',
      })
      if(value?.inv?.noFaktur){
        newData.push({
          no: "",
          desc: `Faktur Pajak ${value?.inv?.noFaktur}`,
          sheet: '2',
          check1: '',
          check2: '',
        })
      }
      value?.inv?.invDetail.forEach((val: any, index: number) => {
        newData.push({
          no: '',
          desc: `Surat Jalan ${value?.inv?.invDetail[0]?.client?.broker?.name || "PT. MERASETI TRANSPORTASI INDONESIA"} nomor ${val?.plotting?.suratJalanNo}`,
          sheet: '1',
          check1: '',
          check2: '',
        })

        if(val?.plotting?.dispatch[0]?.complementaryDocs){
          const splitDocs = val?.plotting?.dispatch[0]?.complementaryDocs.split(",\r\n")
          splitDocs.forEach((valSplit: string) => {
            newData.push({
              no: '',
              desc: valSplit,
              sheet: '1',
              check1: '',
              check2: '',
            })
          })
        }
      })
      
    });

    // end set data

    let textWidth
    let textOffset

    doc.setFont('Arial', 'bold')
    doc.setFontSize(14)
    textWidth = (doc.getStringUnitWidth('TANDA TERIMA') * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20

    doc.text('TANDA TERIMA', textOffset, 20)

    doc.setFontSize(13)
    textWidth = (doc.getStringUnitWidth(ttdNo.toUpperCase()) * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text(ttdNo.toUpperCase(), textOffset, 25)

    
    doc.setFont('arial', 'normal')
    doc.setFontSize(9)
    textWidth = (doc.getStringUnitWidth("TANGGAL") * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text('TANGGAL', textOffset, 32)

    doc.setFont('arial', 'normal')
    textWidth = (doc.getStringUnitWidth(createDate) * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text(createDate, textOffset, 37 )

    doc.setFont('arial', 'normal')
    doc.text('TELAH DISERAHKAN KEPADA:', 20, 40, { align: 'left' })
    doc.setFont('arial', 'bold')
    doc.setFontSize(12)
    doc.text(clientName, 20, 45, { align: 'left' })

    doc.setFont('arial', 'normal')
    doc.setFontSize(9)
    doc.text('ALAMAT:', 20, 50, { align: 'left' })
    doc.setFontSize(8)
    const splitText = doc.splitTextToSize(clientAddress, 100);
    // doc.text(splitText, 15, 50, { align: 'left' })
    splitText.forEach((line: string | string[], index: number) => {
        doc.text(line, 20, 55 + index * 3);
    });

    doc.setFontSize(9)
    doc.setFont('arial', 'normal')
    doc.text('DENGAN RINCIAN SEBAGAI BERIKUT:', 20, 55 + splitText.length * 3 + 2)

    const col_fields = [
      {title: 'NO', field: 'no', rowspan: 2, colspan: 1},
      {title: 'KETERANGAN', field: 'desc', rowspan: 1, colspan: 1},
      {title: 'LEMBAR', field: 'sheet', rowspan: 1, colspan: 1},
      {title: 'CHECK', field: 'check1', rowspan: 1, colspan: 1},
    ]

    doc.setFontSize(8)
    autoTable(doc, {
      startY: 60 + splitText.length * 3 + 2,
      margin: {left: 20},
      theme: 'grid',
      columns: col_fields.map((col) => ({...col, dataKey: col.field, rowspan: col?.rowspan, colspan: col?.colspan})),
      body: newData,
      columnStyles: {
        0: {cellWidth: 10, halign: 'center', valign: 'middle', fontSize: 9},
        1: {cellWidth: 120, halign: 'left', valign: 'middle', fontSize: 9},
        2: {cellWidth: 20, halign: 'center', valign: 'middle', fontSize: 9},
        3: {cellWidth: 20, halign: 'center', valign: 'middle', fontSize: 9},
      },
      didDrawCell: (data) => {
        if (data.row.section === 'body' && data.column.dataKey === 'check1') {
          // Calculate middle x position of the cell
          const middleX = data.cell.x + (data.cell.width * 0.5);
          
          // Draw a vertical line in the middle of the cell
          doc.setLineWidth(0.25);
          doc.line(middleX, data.cell.y, middleX, data.cell.y + data.cell.height);
        }
      },
    })

    let tableY = (doc as any).lastAutoTable.finalY;
    const pageHeight= doc.internal.pageSize.height;

    doc.setFontSize(9)
    if (tableY + 50 > pageHeight - 10) {
      doc.addPage()
      tableY = 20
      doc.setFont('calibri', 'normal')
    }

    textWidth = (doc.getStringUnitWidth('Jakarta, ............................ 2023') * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text('Jakarta, ............................ 2023', textOffset, tableY+10)

    doc.text('Yang Menyerahkan,', 28, tableY+20)
    doc.text('Yang Menerima,', 156, tableY+20)

    doc.text('(___________________________)', 20, tableY+50, {align: 'left'})

    textWidth = (doc.getStringUnitWidth('(___________________________)') * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text('(___________________________)', textOffset, tableY+50)

    const totalPages = doc.getNumberOfPages()

    doc.setFont('arial', 'normal')
    doc.setFontSize(9)
    const centerX = (doc.internal.pageSize.width) / 2;
    for(let i=1; i<= totalPages; i++){
      doc.setPage(i)
      textWidth = (doc.getStringUnitWidth(`Halaman ${i} dari ${totalPages}`) * doc.getFontSize()) / doc.internal.scaleFactor
      textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
      doc.text(`Halaman ${i} dari ${totalPages}`, textOffset, 10)
      doc.text(`${i}`, centerX, doc.internal.pageSize.height - 10);
    }

    doc.save(`${ttdNo}.pdf`)

  } else {
    doc.setFontSize(14)
    doc.text('Error', 80, 20)
    doc.save(`error.pdf`)
  }
  
}

export {InvoiceTTDPDF}
