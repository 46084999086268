import { ID, Response } from '../../_metronic/helpers'

// Declare kebutuhan form Employee
export type Employee = {
  id?: string
  no?: number
  idEmployee?: string,
  supervisor1?: string,
  supervisor2?: string,
  companyId?: string,
  companyName?: string,
  companyLocationId?: string,
  name?: string,
  status?: number,
  phoneNumber?: string,
  address?: string,
  registeredAddress?: string,
  gender?: string,
  religion?: string,
  nationality?: string,
  // height?: number,
  // weight?: number,
  birthPlace?: string,
  dateOfBirth?: string,
  maritalStatus?: string,
  bloodType?: string,
  empPhoto?: string,
  file?: string,
  updated_at?: string,
  created_at?: string
}

export type EmployeeQueryResponse = Response<Array<Employee>>

export const initialEmployee: Employee = { 
  companyId: '',
  companyLocationId: '',
  companyName: '',
  idEmployee: '',
  supervisor1: '',
  supervisor2: '',
  name: '',
  status: 0,
  phoneNumber: '',
  address: '',
  registeredAddress: '',
  gender: '',
  religion: '',
  nationality: '',
  // height: 0,
  // weight: 0,
  birthPlace: '',
  dateOfBirth: '',
  maritalStatus: '',
  bloodType: '',
  empPhoto: '',
  file:'',
  updated_at: '',
  created_at: ''
}

export type EmployeeUpload = FormData

// Employee Document
export type Personal = {
  id?: ID
  no?: number
  employeeId?: string,
  documenstId?: string
  docFile?: string,
}

export type PersonalQueryResponse = Response<Array<Personal>>

export const initialPersonal: Personal = {
  employeeId: '',
  documenstId: '',
  docFile: '',
}

export type PersonalDetailUpload = FormData

// Certification
export type Education = {
  id?: ID
  no?: number
  employeeId?: string,
  empEduLevel?: string
  empEduCity?: string,
  empEduName?: string,
  empEduMajoring?: string,
  empEduStartyear?: string,
  empEduEndyear?: string,
  empEduStatus?: string,
}

export type EmployeeForUpdate = {
  id?: ID
  no?: number
  employeeId?: string,
  employeeData?: {}, 
}

export type EducationQueryResponse = Response<Array<Education>>

export const initialEducation: Education = {
  employeeId: '',
  empEduLevel: '',
  empEduCity: '',
  empEduName: '',
  empEduMajoring: '',
  empEduStartyear: '',
  empEduEndyear: '',
  empEduStatus: '',
}

// Work
export type Work = {
  id?: ID
  no?: number
  employeeId?: string,
  workStartYear?: string
  workEndYear?: string,
  workCompanyName?: string,
  workCompanyAddress?: string,
  workResignReason?: string,
  workRoles?: string,
  workRolesDescription?: string,
  workStatus?: string,
  workSalary?: string,
  benefitDesc?: string,
}

export type WorkQueryResponse = Response<Array<Work>>

export const initialWork: Work = {
  employeeId: '',
  workStartYear: '',
  workEndYear: '',
  workCompanyName: '',
  workCompanyAddress: '',
  workResignReason: '',
  workRoles: '',
  workRolesDescription: '',
  workStatus: '',
  workSalary: '',
  benefitDesc: '',
}

// Certification
export type Certification = {
  id?: ID
  no?: number
  employeeId?: string,
  certName?: string
  certStartyear?: string,
  certEndyear?: string,
  certCompanyName?: string,
  certDescription?: string,
}

export type CertificationQueryResponse = Response<Array<Certification>>

export const initialCertification: Certification = {
  employeeId: '',
  certName: '',
  certStartyear: '',
  certEndyear: '',
  certCompanyName: '',
  certDescription: '',
}

// Language
export type Relative = {
  id?: ID
  no?: number
  employeeId?: string,
  relType?: string
  relName?: string,
  relGender?: string,
  relDate?: string,
  relLastEdu?: string,
  relWorking?: string,
  relDescription?: string,
  relStatus?: string,
}

export type RelativeQueryResponse = Response<Array<Relative>>

export const initialRelative: Relative = {
  employeeId: '',
  relType: '',
  relName: '',
  relGender: '',
  relDate: '',
  relLastEdu: '',
  relWorking: '',
  relDescription: '',
  relStatus: ''
}

// Language
export type Language = {
  id?: ID
  no?: number
  employeeId?: string,
  emplanguages?: string,
  empLang?: string,
  langRead?: string,
  langWrite?: string,
  langListen?: string,
  langSpeak?: string,
}

export type LanguageQueryResponse = Response<Array<Language>>

export const initialLanguage: Language = {
  employeeId: '',
  emplanguages: '',
  empLang: '',
  langRead: '',
  langWrite: '',
  langListen: '',
  langSpeak: '',
}

// Emergency
export type Emergency = {
  id?: ID
  no?: number
  employeeId?: string,
  emgType?: string
  emgName?: string,
  emgPhone?: string,
  emgAddress?: string,
}

export type EmergencyQueryResponse = Response<Array<Emergency>>

export const initialEmergency: Emergency = {
  employeeId: '',
  emgType: '',
  emgName: '',
  emgPhone: '',
  emgAddress: '',
}

export type employeeField = {
  tabEmployee: string
  tabPersonal: string
  tabEducation: string 
  tabWork: string
  tabCertification: string
  tabRelative: string
  tabLanguage: string
  tabEmergency: string
}

export const initialEmployeeField: employeeField = {
  tabEmployee: '',
  tabPersonal: '',
  tabEducation: '', 
  tabWork: '',
  tabCertification: '',
  tabRelative: '',
  tabLanguage: '',
  tabEmergency: ''
}
