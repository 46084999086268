import axios from "axios"
import * as authHelper from 'src/app/context/auth/AuthHelpers'

const DEV_API = process.env.REACT_APP_DEV_API

const TRCK_DASHBOARD = `${DEV_API}/trucking-dashboard`

const getTruckingDashboard = (query:string) => {
    return axios.get(`${TRCK_DASHBOARD}/all`, {headers: { Authorization: `Bearer ${authHelper.getAuth()}` }}).then((response) =>{
        return response.data.data
    })
}

export {getTruckingDashboard}