import {jsPDF} from 'jspdf'
import autoTable from 'jspdf-autotable'
import { getInvoiceTTD } from 'src/app/api/invoice-ttd-api'
import dateFormat from 'dateformat'

const InvoiceBulkPDF = async (id: string) => {
  function currencyFormat(num: number) {
    if (num !== undefined) {
      return 'IDR ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      return ''
    }
  }

  let query: any = new URLSearchParams({
    limit: String(9999),
    offset: String(0),
    type: "bulk-invoice",
    bulk_id: id,
  }).toString()
  const resData = await getInvoiceTTD(query)

  const doc = new jsPDF()

  if (resData.status === 200) {
    // set data here

    const data = resData?.data

    const bulkNo = `INV-BULK-${data[0]?.inv?.invDetail[0]?.client?.clientCode}-${dateFormat(data[0]?.bulkCreatedAt, 'mmdd')}${data[0]?.bulkId}`
    const createDate = data[0]?.created_at ? dateFormat(data[0]?.bulkCreatedAt, "mmm dd, yyyy") : ''
    const clientName = data[0]?.inv?.invDetail[0]?.client?.clientName ? data[0]?.inv?.invDetail[0]?.client?.clientName : ''
    const clientAddress = data[0]?.inv?.invDetail[0]?.client?.clientAddress ? data[0]?.inv?.invDetail[0]?.client?.clientAddress : ''
    let newData: any[] = []

    let total_amount = 0

    data.forEach((value: any, id: number) => {
        newData.push({
            no: id+1,
            inv_no: value?.inv?.invNo,
            total_sales: currencyFormat(value?.inv?.invSubtotal),
            ppn: currencyFormat(value?.inv?.invPpn),
            pph: currencyFormat(value?.inv?.invPph),
            total_pay: currencyFormat(value?.inv?.invTotal)
        })
        total_amount = total_amount + Number(value?.inv?.invTotal)
    });

    // end set data

    let textWidth
    let textOffset

    doc.setFont('Arial', 'bold')
    doc.setFontSize(14)
    textWidth = (doc.getStringUnitWidth('PEMBAYARAN INVOICE') * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20

    doc.text('PEMBAYARAN INVOICE', textOffset, 20)

    doc.setFontSize(13)
    textWidth = (doc.getStringUnitWidth(bulkNo.toUpperCase()) * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text(bulkNo.toUpperCase(), textOffset, 25)

    
    doc.setFont('arial', 'normal')
    doc.setFontSize(9)
    textWidth = (doc.getStringUnitWidth("TANGGAL") * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text('TANGGAL', textOffset, 32)

    doc.setFont('arial', 'normal')
    textWidth = (doc.getStringUnitWidth(createDate) * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text(createDate, textOffset, 37 )

    doc.setFont('arial', 'normal')
    doc.text('NAMA CUSTOMER:', 20, 40, { align: 'left' })
    doc.setFont('arial', 'bold')
    doc.setFontSize(12)
    doc.text(clientName, 20, 45, { align: 'left' })

    doc.setFont('arial', 'normal')
    doc.setFontSize(9)
    doc.text('ALAMAT:', 20, 50, { align: 'left' })
    doc.setFontSize(8)
    const splitText = doc.splitTextToSize(clientAddress, 100);
    // doc.text(splitText, 15, 50, { align: 'left' })
    splitText.forEach((line: string | string[], index: number) => {
        doc.text(line, 20, 55 + index * 3);
    });

    doc.setFontSize(9)
    doc.setFont('arial', 'normal')
    doc.text('DENGAN RINCIAN SEBAGAI BERIKUT:', 20, 55 + splitText.length * 3 + 2)

    const col_fields = [
      {title: 'NO', field: 'no', rowspan: 2, colspan: 1},
      {title: 'NO INVOICE', field: 'inv_no', rowspan: 1, colspan: 1},
      {title: 'TOTAL SALES', field: 'total_sales', rowspan: 1, colspan: 1},
      {title: 'PPN', field: 'ppn', rowspan: 1, colspan: 1},
      {title: 'PPH', field: 'pph', rowspan: 1, colspan: 1},
      {title: 'TOTAL PENERIMAAN', field: 'total_pay', rowspan: 1, colspan: 1},
    ]

    doc.setFontSize(8)
    autoTable(doc, {
      startY: 60 + splitText.length * 3 + 2,
      margin: {left: 20},
      theme: 'grid',
      columns: col_fields.map((col) => ({...col, dataKey: col.field, rowspan: col?.rowspan, colspan: col?.colspan})),
      body: newData,
      columnStyles: {
        0: {cellWidth: 10, halign: 'center', valign: 'middle', fontSize: 9},
        1: {cellWidth: 42, halign: 'center', valign: 'middle', fontSize: 9},
        2: {cellWidth: 32, halign: 'center', valign: 'middle', fontSize: 9},
        3: {cellWidth: 27, halign: 'center', valign: 'middle', fontSize: 9},
        4: {cellWidth: 27, halign: 'center', valign: 'middle', fontSize: 9},
        5: {cellWidth: 32, halign: 'center', valign: 'middle', fontSize: 9},
      }
    })

    let tableY = (doc as any).lastAutoTable.finalY;

    const totalCol = [
      { title: "", field: "kolom1", rowSpan: 2 },
      { title: "", field: "kolom2", },
      { title: "", field: "kolom3" },
      { title: "", field: 'kolom4' },
      { title: "", field: "kolom5" },
      { title: "", field: 'kolom6' }]
    
    const dataTotalCol = [
      {
        kolom1: '',
        kolom2: '',
        kolom3: '',
        kolom4: '',
        kolom5: '',
        kolom6: '',
      },
      {
        kolom1: '',
        kolom2: '',
        kolom3: '',
        kolom4: '',
        kolom5: 'TOTAL',
        kolom6: currencyFormat(total_amount),
      },
    ]

    autoTable(doc, {
      startY: tableY,
      theme: 'grid',
      margin: {left: 20},
      columns: totalCol.map(col => ({ ...col, dataKey: col.field, rowSpan: 3 })),
      body: dataTotalCol,
      columnStyles: {
        0: { cellWidth: 10 },
        1: { cellWidth: 42 },
        2: { cellWidth: 32 },
        3: { cellWidth: 27 },
        4: { cellWidth: 27 },
        5: { cellWidth: 32, halign: 'right' }
      }
    })

    tableY = (doc as any).lastAutoTable.finalY;
    const pageHeight= doc.internal.pageSize.height;

    doc.setFontSize(9)
    if (tableY + 50 > pageHeight - 10) {
      doc.addPage()
      tableY = 20
      doc.setFont('calibri', 'normal')
    }

    textWidth = (doc.getStringUnitWidth('Jakarta, ............................ 2023') * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text('Jakarta, ............................ 2023', textOffset, tableY+10)

    doc.text('Yang Mengajukan,', 29, tableY+20)
    doc.text('Yang Menyetujui,', 156, tableY+20)

    doc.text('(___________________________)', 20, tableY+50, {align: 'left'})

    textWidth = (doc.getStringUnitWidth('(___________________________)') * doc.getFontSize()) / doc.internal.scaleFactor
    textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
    doc.text('(___________________________)', textOffset, tableY+50)

    textWidth = (doc.getStringUnitWidth('Yang Mengajukan,') * doc.getFontSize()) / doc.internal.scaleFactor;
    textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
    doc.text('Yang Mengetahui,', textOffset, tableY+20)

    textWidth = (doc.getStringUnitWidth('(___________________________)') * doc.getFontSize()) / doc.internal.scaleFactor;
    textOffset = (doc.internal.pageSize.getWidth() - textWidth) / 2;
    doc.text('(___________________________)', textOffset, tableY+50)

    const totalPages = doc.getNumberOfPages()

    doc.setFont('arial', 'normal')
    doc.setFontSize(9)
    const centerX = (doc.internal.pageSize.width) / 2;
    for(let i=1; i<= totalPages; i++){
        doc.setPage(i)
        textWidth = (doc.getStringUnitWidth(`Halaman ${i} dari ${totalPages}`) * doc.getFontSize()) / doc.internal.scaleFactor
        textOffset = doc.internal.pageSize.getWidth() - textWidth - 20
        doc.text(`Halaman ${i} dari ${totalPages}`, textOffset, 10)
        doc.text(`${i}`, centerX, doc.internal.pageSize.height - 10);
    }

    doc.save(`${bulkNo}.pdf`)

  } else {
    doc.setFontSize(14)
    doc.text('Error', 80, 20)
    doc.save(`error.pdf`)
  }
  
}

export {InvoiceBulkPDF}
