import { number, string } from 'yup'
import { ID, Response } from '../../_metronic/helpers'
export type EVoucher = {
  id?: ID | string
  no?: number
  status?: string
  companyName?: string
  voucherNo?: string
  jobId?: string
  jobNo?: string
  document?: []
  amount?: number | string
  taxInput?: number
  userId: string
  isAcknowledge?: number
  created_at?: string
  deleted_at?: string
  updated_at?: string
  totalPPN?: number
  totalPPH?: number
}

export const initialData: EVoucher = {
  companyName: '',
  jobId: '',
  amount: '',
  voucherNo: '',
  userId: ''
}

export type EVoucherDoc = {
  voucherId?: string
  name: string
  date: string
  url: string
}

export type GetEVoucherResponse = {
  id: string
  voucher_no: string
  total_amount: number
  company_id: string
  company_name: string
  job_id: string
  job_no: string
  voucher_status: string
  documents: []
  userId: string
  tax_input: number
  is_paid: number
  is_acknowledged: number
  created_at: string
  total_ppn: string
  total_pph: string
}

export type voucherDetails = {
  id: string
  vDescription: string
  vAmount: string
  ppnId: string
  ppnAmount: number
  ppn: number
  pphId: string
  pphAmount: number
  pph: number
}

export type CreateVoucherRequest = {
  companyId: string
  jobOrderId: string
  voucherNo: string
  voucherDate: string
  voucherAmount: number
  voucherDetail: voucherDetails[]
  is_draft: number
  is_advance: number
}

export type rejectVoucherRequest = {
  voucherId: string
  reason?: string
}

type getVoucherByIdVoucherDetailResponse = {
  id: string
  voucherId: string
  vDescription: string
  ppnId: string
  ppnAmount: string
  ppn: number
  pphId: string
  pphAmount: string
  pph: number 
  vAmount: number
  created_at: string
  updated_at: string
  deleted_at: string
}

type getVoucherByIdVoucherDocResponse = {
  id: string
  voucherId: string
  documentId: string
  vDocFile: string
  created_at: string
  updated_at: string
  deleted_at: string
  document: {
    id: string
    docName: string
  },
  url: string
}

export type getVoucherByIdResponse = {
  id: string
  companyId: string
  userId: string
  jobOrderId: string
  voucherNo: string
  voucherAmount: number
  voucherTax: number
  voucherTaxType: string
  voucherDate: string
  voucherPaymentType: string
  bankName:string
  bankAccountName:string
  bankAccountNumber:number
  isAdvance: number
  approvalAh: number
  approvalChecker: number
  approvalFh: number
  approvalFd: number
  created_at: string
  updated_at: string
  deleted_at: string
  voucherDetail: getVoucherByIdVoucherDetailResponse[],
  voucherDoc: getVoucherByIdVoucherDocResponse[]
}

type updateVoucherDetailRequest = {
  id: string
  vDescription: string
  vAmount: number

}

export type updateVoucherRequest = {
  voucher: {
    companyId: string
    jobOrderId: string
    voucherNo: string
    voucherAmount: number
    voucherTax: number
    voucherTaxType: string
    voucherDate: string
    is_draft: number
  },
  voucherDetail: updateVoucherDetailRequest[]
}

export type voucherLog = {
  id: string
  voucher_id: string
  voucher_no: string
  voucher_status: string
  voucher_reason: string
  created_at: string
  username: string
  name: string
  role: string
  message: string
}

export type realisasiLog = {
  id: string
  realisasi_voucher_id: string
  realisasi_no: string
  realisasi_status: string
  realisation_reason: string
  created_at: string
  username: string
  name: string
  role: string
  message: string
}

export type EVoucherQueryResponse = Response<Array<GetEVoucherResponse>>


export const evoucherDummyData = {
  "message": "Success",
  "data": [{
    "id": "2a014486-3c8b-45f4-bf50-3ea1926ae329",
    "status": "draft",
    "companyId": "43c7b96e-ebcb-4ad3-a80d-361157de649a",
    "companyName": "HARTON INTERNATIONAL",
    "voucherNo": "11",
    "jobNo": "job1",
    "document": "view",
    "amount": "2000000"
  }, {
    "id": "2a014486-3c8b-45f4-bf50-3ea1926ae329",
    "status": "waiting",
    "companyId": "43c7b96e-ebcb-4ad3-a80d-361157de649a",
    "companyName": "Company 2",
    "voucherNo": "12",
    "jobNo": "job2",
    "document": "view",
    "amount": "3000000"
  }, {
    "id": "2a014486-3c8b-45f4-bf50-3ea1926ae329",
    "status": "closed",
    "companyId": "43c7b96e-ebcb-4ad3-a80d-361157de649a",
    "companyName": "Company 3",
    "voucherNo": "13",
    "jobNo": "job3",
    "document": "view",
    "amount": "4000000"
  }, {
    "id": "2a014486-3c8b-45f4-bf50-3ea1926ae329",
    "status": "approved",
    "companyId": "43c7b96e-ebcb-4ad3-a80d-361157de649a",
    "companyName": "Company 3",
    "voucherNo": "14",
    "jobNo": "job4",
    "document": "view",
    "amount": "5000000"
  }, {
    "id": "2a014486-3c8b-45f4-bf50-3ea1926ae329",
    "status": "rejected",
    "companyId": "43c7b96e-ebcb-4ad3-a80d-361157de649a",
    "companyName": "Company 4",
    "voucherNo": "15",
    "jobNo": "job5",
    "document": "view",
    "amount": "6000000"
  }]
}

export const evoucherDocDummyData: EVoucherDoc[] =
  [{
    "name": "Some document for voucher",
    "date": "2022-09-30T11:33:08.000Z",
    "url": "https://mdkreatif.id/api-dev/files/VoucherDoc-1664537588067.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lc3RhbXAiOjE2NjQ3NjU2MzksImlhdCI6MTY2NDc2NTYzOSwiZXhwIjoxNjY0NzY2NTM5fQ.pbLinkPON3aAd4iLqWnfkbOQiKfnracc3ppUat-6tRw"
  },
  {
    "name": "Some document for voucher",
    "date": "2022-09-30T11:33:08.000Z",
    "url": "https://mdkreatif.id/api-dev/files/VoucherDoc-1664537588067.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lc3RhbXAiOjE2NjQ3NjU2MzksImlhdCI6MTY2NDc2NTYzOSwiZXhwIjoxNjY0NzY2NTM5fQ.pbLinkPON3aAd4iLqWnfkbOQiKfnracc3ppUat-6tRw"
  },
  {
    "name": "Some document for voucher",
    "date": "2022-09-30T11:33:08.000Z",
    "url": "https://mdkreatif.id/api-dev/files/VoucherDoc-1664537588067.jpg?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0aW1lc3RhbXAiOjE2NjQ3NjU2MzksImlhdCI6MTY2NDc2NTYzOSwiZXhwIjoxNjY0NzY2NTM5fQ.pbLinkPON3aAd4iLqWnfkbOQiKfnracc3ppUat-6tRw"
  }]
