export const FEATURE_TYPE = {
  mdVehicle: 'MD Vehicle',
  mdVehicleBrand: 'MD Vehicle Brand',
  mdVehicleModel: 'MD Vehicle Model',
  mdVehicleType: 'MD Vehicle Type',
  mdDocument: 'MD Documents',
  mdPricing: 'MD Pricing',
  mdPricingCategory: 'MD Pricing Category',
  mdPricingType: 'MD Pricing Type',
  mdPricingPrice: 'MD Pricing Price',
  mdCompany: 'MD Company',
  mdCompanyLoc: 'MD Company Location',
  mdDriver: 'MD Driver',
  mdDriverAllowance: 'MD Driver Allowance',
  mdDriverUangJajan: 'MD Driver Uang Jajan',
  mdDriverAdditionalCost: 'MD Driver Additional Cost',
  mdFeature: 'MD Feature',
  mdArea: 'MD Area',
  mdRole: 'MD Role',
  mdUser: 'MD User',
  mdEmployee: 'MD Employee',
  Equipment : 'Equipment',
  mdJobOrder: 'MD Job Order',
  mdClient: 'MD Client',
  mdClientContract: 'MD Client Contract',
  mdClientPricing: 'MD Client Pricing',
  inbound: 'Inbound',
  inboundDetail: 'Inbound Detail',
  suratJalan: 'Surat Jalan',
  dispatch: 'Dispatch',
  mdPlotting : "MD Plotting",
  AllowancePayment : 'Allowance Payment',
  invoice : 'Invoice',
  depo: 'Depo',
  EquipmentReturn: 'Equipment Return',
  EquipmentOrder: 'Equipment Return',
  EquipmentCheck: 'Equipment Check',
  EquipmentStock: 'Equipment Stock',
  Maintenance : 'Maintenance',
  Storing: 'Storing',
  mdPricingUnit : 'MD Pricing Unit',
  sparePart : 'MD Sparepart',
  SparepartStock: 'MD Sparepart',
  mdCountry: 'MD Country',
  mdShipperImportir: 'MD Shipper Importir',
  mdVendorType : 'MD Vendor Type',
  mdVendor : 'MD Vendor',
  mdHsCode : 'MD HS CODE',
  mdProduct : 'MD Product',
  mdClientPricingImp : 'MD Client Pricing Importir',
  mdJobOrderImp : 'Md Job Order Importir',
  mdUOM : 'MD UOM',


  eVoucher: 'Voucher',
  mdPpn : 'MD PPN',
  mdPph : 'MD PPH',

  invoiceImp : 'Invoice Importir',

  truckingDashboard : "Trucking Dashboard",

  broker: "MD Broker",

  invoiceTtd: "Invoice TTD",

  invoiceBulk: "Invoice Bulk",

  refundUJ: "Refund UJ",

  invoicePaid: "Invoice Paid",

  invoiceNotPaid: "Invoice Not Paid"
}
