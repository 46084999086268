/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Dropdown1 } from '../../content/dropdown/Dropdown1'
import { KTSVG, toAbsoluteUrl } from 'src/_metronic/helpers'

import { EVoucherDoc, EVoucher } from 'src/app/models/evoucher-model'
import { acknowledgeVoucher, deleteEVoucher } from 'src/app/api/evoucher-api'

import { useListEVoucherView } from 'src/app/context/evoucher/EVoucherViewProvider'
import { useQueryResponseEVoucher, useQueryResponseEVoucherData, useQueryResponseEVoucherLoading } from 'src/app/context/evoucher/QueryResponseProvider'
import { useAuth } from 'src/app/context/auth/AuthProvider'

type Props = {
  className: string
  color: string
}

const MixedWidgetVoucher: React.FC<Props> = ({ className, color }) => {
  const evoucher = useQueryResponseEVoucher()
  const totalAmount = evoucher.response?.summary?.totalVouchersAmount
  const totalVoucher = evoucher.response?.count
  const totalByStatus = evoucher.response?.summary?.groupedVoucherByStatus 
  const totalByType = evoucher?.response?.summary?.voucherTypeCount
  console.log('testing re build git flow')
  
  function currencyFormat(num: number) {
    if (num !== undefined) {
      return 'Rp. ' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } else {
      return 0
    }
  }

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100 bg-${color}`}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bolder fs-3'>e-Voucher Summary</h3>
            <div className='ms-1'>
              {/* begin::Menu */}
              <button
                type='button'
                className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                data-kt-menu-flip='top-end'
              >
                <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
              </button>
              <Dropdown1 />
              {/* end::Menu */}
            </div>
          </div>

          <div className='row g-5 g-xl-12'>
            {/*  */}
            <div className='col-xl-6'>
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span className='fw-bold fs-7'>Submission Total</span>
                <span className='fw-bolder fs-2x pt-1'>{totalVoucher}</span>
              </div>
            </div>

            <div className='col-xl-6'>
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span className='fw-bold fs-7'>Submission Amount Total</span>
                <span className='fw-bolder fs-2x pt-1'>{currencyFormat(totalAmount)}</span>
              </div>
            </div>
          </div>

          {/* start tipe job and non job */}

          <div className='row g-5 g-xl-12'>
            {/*  */}
            <div className='col-xl-6'>
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span className='fw-bold fs-7'>Total Job</span>
                <span className='fw-bolder fs-2x pt-1'>{totalByType?.['Job']?.count}</span>
                <span className='fw-bolder fs-5 pt-1'>{totalByType?.['Job']?.amount === 0 ? 'Rp. 0' : currencyFormat(parseInt(totalByType?.['Job']?.amount))}</span>

              </div>
            </div>

            <div className='col-xl-6'>
              <div className='d-flex text-center flex-column text-white pt-3'>
                <span className='fw-bold fs-7'>Total Non-Job</span>
                <span className='fw-bolder fs-2x pt-1'>{totalByType?.['NonJob']?.count}</span>
                <span className='fw-bolder fs-5 pt-1'>{totalByType?.['NonJob']?.amount === 0 ? 'Rp. 0' : currencyFormat(parseInt(totalByType?.['NonJob']?.amount))}</span>
              </div>
            </div>
          </div>

        </div>
        {/* end::Header */}

        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-white'
          style={{ marginTop: '-40px' }}
        >
          <div className='table-responsive'>
            <table className='table align-middle gs-0 gy-4'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bolder text-muted bg-light'>
                  <th className='ps-4 min-w-125px rounded-start'>Status</th>
                  <th className='min-w-125px'>Voucher</th>
                  <th className='min-w-150px'>Amount</th>
                </tr>
              </thead>
              {/* end::Table head */}

              {/* begin::Table body */}
              <tbody>  

                <tr>
                  <td>
                    <span className='badge badge-light-primary fs-7 fw-bold'>Waiting Approval Head</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{totalByStatus?.['Waiting Approval head']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(totalByStatus?.['Waiting Approval head']?.total_amount)}</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className='badge badge-light-danger fs-7 fw-bold'>Waiting Tax Staff Approval</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{totalByStatus?.['Waiting Checker Approval']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(totalByStatus?.['Waiting Checker Approval']?.total_amount)}</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className='badge badge-light-success fs-7 fw-bold'>Waiting Checker Approval</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{totalByStatus?.['Waiting Checker Approval']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(totalByStatus?.['Waiting Checker Approval']?.total_amount)}</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className='badge badge-light-info fs-7 fw-bold'>Waiting Finance Head Approval</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{totalByStatus?.['Waiting FH Approval']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(totalByStatus?.['Waiting FH Approval']?.total_amount)}</a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span className='badge badge-light-warning fs-7 fw-bold'>Waiting Finance Director Approval</span>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{totalByStatus?.['Waiting FD Approval']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(totalByStatus?.['Waiting FD Approval']?.total_amount)}</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className='badge badge-light-primary fs-7 fw-bold'>Waiting Acknowledgement</span>
                  </td> 
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{totalByStatus?.['Paid-Waiting Acknowledgement']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(totalByStatus?.['Paid-Waiting Acknowledgement']?.total_amount)}</a>
                  </td>
                </tr>

                <tr>
                  <td>
                    <span className='badge badge-light-primary fs-7 fw-bold'>Waiting Realisation</span>
                  </td> 
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{totalByStatus?.['Payment Received-waiting realisasi']?.count}</a>
                  </td>
                  <td>
                    <a href='#' className='text-dark fw-bolder text-hover-primary mb-1 fs-6'>{currencyFormat(totalByStatus?.['Payment Received-waiting realisasi']?.total_amount)}</a>
                  </td>
                </tr>
              </tbody>
              {/* end::Table body */}
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export { MixedWidgetVoucher }
