import axios from 'axios'
import * as authHelper from 'src/app/context/auth/AuthHelpers'

const customAxios = axios.create({
    
})

customAxios.interceptors.request.use((config) => {
    const token = authHelper.getAuth();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    console.log('error == custom-axios')
    return Promise.reject(error);
  }
)

export default customAxios
