import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../_metronic/layout/core'
import { AuthModel, UserFeatureModel, UserModel } from '../../models/auth-model'
import * as authHelper from './AuthHelpers'
import { getUserByToken } from '../../api/auth-api'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  currentUserFeature: UserFeatureModel[] | undefined
  setCurrentUserFeature: Dispatch<SetStateAction<UserFeatureModel[] | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  currentUserFeature: [],
  setCurrentUserFeature: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [currentUserFeature, setCurrentUserFeature] = useState<UserFeatureModel[] | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider
      value={{
        auth,
        saveAuth,
        currentUser,
        setCurrentUser,
        currentUserFeature,
        setCurrentUserFeature,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC = ({ children }) => {
  const { auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  // We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        // if (!didRequest.current) {
        //   const { data } = await getUserByToken(apiToken)
        //   if (data) {
        //     setCurrentUser(data)
        //   }
        // }
		  if (!didRequest.current) {
			  const { data: user } = await getUserByToken(apiToken)
			  setCurrentUser(user.data)
			  //   if (data) {
			  // setCurrentUser(data)
			  //   }
		  }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    // if (auth && auth.token) {
    //   requestUser(auth.token)
    // } else {
    //   logout()
    //   setShowSplashScreen(false)
    // }

	  if (auth) {
		  requestUser(auth.toString())
	  } else {
		  logout()
		  setShowSplashScreen(false)
	  }
    // eslint-disable-next-line
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
