import {useCallback, useEffect, useState} from 'react'

import clsx from 'clsx'
import { useLayout } from '../../../../../../_metronic/layout/core'

import Select from 'react-select'
import { Buttons } from 'src/_metronic/layout/components/button/Button'
import { useNavigate, useParams } from "react-router-dom"
import { getCompany } from 'src/app/api/company-api'
import { getBanks } from 'src/app/api/evoucher-api'
import { getPph } from 'src/app/api/pph-api'
import { addBrokers, getDetailBroker, updateBrokers } from 'src/app/api/broker-api'
import { getInvoiceTTD } from 'src/app/api/invoice-ttd-api'

interface InvTTDDetailProps{
  id?: string;
  invId?: string;
  invDocFile?: string;
  invDocFileBulk?: string;
  invDocType?: string;
  paymentTypeInvoice?: string;
  paymentStatus?: string;
  ttdId?: number;
  bulkId?: number;
  ttdCreatedAt?: string;
  bulkCreatedAt?: string;
  ttdCreatedBy?: string | null;
  bulkCreatedBy?: string | null;
  ttdUpdatedAt?: string | null;
  bulkUpdatedAt?: string | null;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string | null;
  inv?: {
    id?: string;
    companyId?: string;
    no?: number;
    invNo?: string;
    invSubtotal?: number;
    invPpn?: number;
    invPph?: number;
    invTotal?: number;
    invDate?: string;
    invSentDate?: string | null;
    invDueDate?: string | null;
    invPayDate?: string | null;
    invNote?: string | null;
    noFaktur?: string | null;
    invType?: number;
    status?: number;
    created_at?: string;
    updated_at?: string;
    deleted_at?: string | null;
    company_id?: string;
    invDetail: {
      id?: string;
      invId?: string;
      jobOrderId?: string | null;
      plottingId?: string;
      clientId?: string;
      clientPricingId?: string;
      invNoRef?: string | null;
      invItem?: string | null;
      invQty?: number;
      created_at?: string;
      updated_at?: string;
      deleted_at?: string | null;
      plotting?: {
        id?: string;
        jobOrderId?: string;
        jobOrderTracker?: string;
        vehicleId?: string;
        driverId?: string;
        no?: number;
        plottingNo?: string;
        nosj?: number;
        suratJalanNo?: string;
        tonase?: number | null;
        flagInv?: number | null;
        status?: number | null;
        createdBy?: string;
        updatedBy?: string;
        created_at?: string;
        updated_at?: string;
        deleted_at?: string | null;
        md_vehicle_id?: string;
        md_driver_id?: string;
        md_job_order_id?: string;
        joborder_tracker?: string;
        dispatch?: {
          id?: string;
          plottingId?: string;
          timeIn?: string | null;
          timeOut?: string | null;
          fileDispatch?: string | null;
          cancelStatus?: number | null;
          remarks?: string | null;
          complementaryDocs?: string | null;
          docsCount?: number | null;
          created_at?: string;
          updated_at?: string;
          deleted_at?: string | null;
          id_plotting?: string;
        }[]
      };
      client?: {
        id?: string;
        companyId?: string;
        brokerId?: string;
        clientName?: string;
        clientAddress?: string;
        clientTelpno?: string;
        clientTop?: number;
        clientCode?: string;
        isPpn?: number;
        isPph?: number;
        cancelFee?: number;
        status?: number;
        created_at?: string;
        updated_at?: string;
        deleted_at?: string | null;
        pphId?: string | null;
      }
    }[]
  }
}

const InvoiceTTDDetail = () => {
    // data prep
    const {id} = useParams()
    const navigate = useNavigate()
    const { classes } = useLayout()
    const [invTTDDetail, setInvTTDDetail] = useState<InvTTDDetailProps[]>([])

    function currencyFormat(num: number) {
      if (num !== undefined) {
        return 'Rp. ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      } else {
        return 0
      }
    }

    const GetInvoiceTTDDetail = useCallback(async () => {
      try {
        let query: any = new URLSearchParams({
          limit: String(9999),
          offset: String(0),
          type: "ttd",
        }).toString()

        if(id) {
          query += `&ttd_id=${id}`
        }
        const data = await getInvoiceTTD(query)
        setInvTTDDetail(data?.data)
      } catch (error) {
        //
      }
    }, [id])

    useEffect(() => {
      GetInvoiceTTDDetail()
    },[GetInvoiceTTDDetail])

    return (
      <>
        <div className='card mb-5 mb-xl-10'>
          <div className='toolbar' id='kt_toolbar'>
            <div
              id='kt_toolbar_container'
              className={clsx(classes.toolbarContainer.join(' '), 'd-flex flex-stack')}
            >
              <h2>
                <b>Invoice TTD Detail</b>
              </h2>
            </div>
          </div>
          <div className='card-body'>
            <div className='row mb-10'>
              <div className="col-1">No.</div>
              <div className='col-4 text-center'>Invoice No</div>
              <div className='col-4 text-center'>Client Name</div>
              <div className="col-3 text-center">Amount</div>
            </div>

            {invTTDDetail.map((value: InvTTDDetailProps, index: number) => {
              return (
                <div className="row mb-10">
                  <div className="col-1">{index+1}</div>
                  <div className="col-4 text-center">{value?.inv?.invNo}</div>
                  <div className="col-4 text-center">{value?.inv?.invDetail[0]?.client?.clientName}</div>
                  <div className="col-3 text-center">{currencyFormat(Number(value?.inv?.invTotal))}</div>
                </div>
              )
            })}
          </div>
          <div>
            <div className='text-end p-15'>
              <Buttons
                type='danger'
                text={'Back'}
                onClickButton={() => {navigate("../list-invoice")}}
              ></Buttons>
            </div>
          </div>
        </div>
      </>
    )
}

export default InvoiceTTDDetail