import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../_metronic/helpers'
import { Invoice, InvoiceQueryResponse, InvoiceDetail, CreateInvoice, InvoicePayment } from '../models/invoice-model'
import { Plotting, PlottingsQueryResponse } from '../models/plotting-model'
import { JobOrder, JobOrdersQueryResponse } from '../models/job-order-model'
import * as authHelper from 'src/app/context/auth/AuthHelpers'
import customAxios from './custom-axios'

const DEV_API = process.env.REACT_APP_DEV_API

const INVOICE_URL = `${DEV_API}/inv`
const AUTH_TOKEN = {headers: { Authorization: `Bearer ${authHelper.getAuth()}` }}

const getInvoice = (query: string): Promise<InvoiceQueryResponse> => {
  return axios.get(`${INVOICE_URL}?${query}`).then((d: AxiosResponse<InvoiceQueryResponse>) => d.data)
}

const getInvoiceById = (id: ID, tipe: number) => {
  return axios
    .get(`${INVOICE_URL}/?id=${id}&invType=${tipe}`)
    .then((response) => {
      return response.data.data
    })
}

const getInvoiceForDetail = (id: ID) => {
  return axios
    .get(`${INVOICE_URL}/?id=${id}`)
    .then((response) => {
      return response.data.data
    })
}

const createInvoice = (invoice: CreateInvoice) => {
  return axios.post(`${DEV_API}/inv`, invoice).then((response) => {
    return response
  })
}

const createInvoiceDetail = (invoice: InvoiceDetail) => {
  return axios.post(`${DEV_API}/inv/detail`, invoice).then((response) => {
    return response
  })
}

const updateInvoice = (invoice: InvoiceDetail): Promise<Invoice | undefined> => {
  let newInvoice = Object.fromEntries(invoice.entries());

  return axios
    .put(`${INVOICE_URL}/${newInvoice.id}`, invoice)
    .then((response: AxiosResponse<Response<Invoice>>) => response.data)
    .then((response: Response<Invoice>) => response.data)
}

const deleteInvoice = (invoiceId: ID) => {
  return axios.delete(`${INVOICE_URL}/${invoiceId}`).then((response) => {
    return response
  })
}

const getPlotting = (query: string, company: string): Promise<PlottingsQueryResponse> => {
  const new_query = "page=1&limit=9999"
  return axios
    .get(`${DEV_API}/plotting?${new_query}&status=plotting&companyId=${company}&noInvoice=true&mustDispatch=true`)
    .then((d: AxiosResponse<PlottingsQueryResponse>) => d.data)
}

const getJobOrder = (query: string, company: string): Promise<JobOrdersQueryResponse> => {
  return axios
    .get(`${DEV_API}/joborder?${query}&companyId=${company}&noInvoice=true`)
    .then((d: AxiosResponse<JobOrdersQueryResponse>) => d.data)
}

const getPlottingByJobId = (id: string) => {
  return axios
    .get(`${DEV_API}/plotting?jobOrderId=${id}`)
    .then((response) => {
      return response.data.data;
    })
}

const getPlottingById = (id: string) => {
  return axios
    .get(`${DEV_API}/inv/job/${id}?jenisinv=plotting`)
    .then((response) => {
      return response.data.data;
    })
}

const getJobOrderById = (id: string) => {
  return axios
    .get(`${DEV_API}/inv/job/${id}?jenisinv=joborder`)
    .then((response) => {
      return response.data.data;
    })
}

const getUnitPriceById = (id: ID) => {
  return axios
    .get(`${DEV_API}/pricing/unit/${id}`)
    .then((response) => {
      return response.data.data
    })
}
 
const getInvoiceForExcel = (query: string) => {
  return axios
    .get(`${INVOICE_URL}?${query}`)
    .then((response) => {
      return response.data.data
    })
}

const createInvPayment = (files: InvoicePayment) => {
  return axios.post(`${INVOICE_URL}/pay`, files, AUTH_TOKEN).then((response) => {
    return response.data
  })
}

const updateInvPayment = (files: InvoicePayment) => {
  return axios
    .put(`${INVOICE_URL}/pay`, files, AUTH_TOKEN).then((response) => {
      return response.data
    })
}

const getInvoicePaymentDocs = (query: string) => {
  return axios
    .get(`${INVOICE_URL}/docs/?invNo=${query}`,AUTH_TOKEN)
    .then((response) => {
      return response.data.data
    })
}

const updateFakturNo = (data: {fakturNo: string}) => {
  return customAxios.put(`${INVOICE_URL}/set-faktur`, data).then((response) => {
    return response
  })
}

const uploadBuktiPajak = async (form: FormData, id: string) => {
  return customAxios.put(`${INVOICE_URL}/set-pajak-pph/${id}`, form).then((res) => res)
}

export { uploadBuktiPajak, getInvoice, getInvoiceById, getInvoiceForDetail, deleteInvoice, createInvoice, createInvoiceDetail, updateInvoice, getPlotting, getJobOrder, getPlottingByJobId, getPlottingById, getJobOrderById, getUnitPriceById, getInvoiceForExcel, createInvPayment, getInvoicePaymentDocs, updateInvPayment, updateFakturNo}