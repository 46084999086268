import {Route, Routes, Outlet} from 'react-router-dom'
import InvoiceTTDList from './invoice-ttd-list/InvoiceTTDList';
import InvoiceTTDCreate from './invoice-ttd-list/invoice-ttd-create/InvoiceTTDCreate';
import InvoiceTTDDetail from './invoice-ttd-list/invoice-ttd-detail/InvoiceTTDDetail';

const InvTTDPage = () => {
    return (
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='list-invoice'
            element={
              <>
                <InvoiceTTDList />
              </>
            }
          />

          <Route
            path='create-ttd'
            element={
              <>
                <InvoiceTTDCreate/>
              </>
            }
          />

          <Route
            path='detail-ttd/:id'
            element={
              <>
                <InvoiceTTDDetail/>
              </>
            }
          />
        </Route>
      </Routes>
    )
  }
  
  export default InvTTDPage;