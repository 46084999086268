import { useCallback } from 'react'
import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import { useTable, ColumnInstance, Column } from 'react-table'
import { KTCardBody } from 'src/_metronic/helpers'
import Pagination from 'src/_metronic/layout/components/pagination'
import { BrokerCustomHeader } from './column/BrokerCustomHeader'
import { CustomHeaderColumn } from './column/CustomHeaderColumn'
import { CustomRow } from './column/CustomRow'
import { getInvoiceTTD } from 'src/app/api/invoice-ttd-api'
import { InvTTDUploadDocModal } from '../component/InvoiceTTDUploadDocModal'
import dateFormat from 'dateformat'
import { InvoiceTTDPDF } from '../CetakInvoiceTTD'

const InvoiceTTDTable = () => {
    const navigate = useNavigate()
    const [columns, setColumns] = useState<Column<any>[]>([])
    const [data, setData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [limit, _] = useState(10);
    const [currentPage, setCurrentPage] = useState(1);
    const [currTTDId, setCurrTTDId] = useState<number>(0)
    const [isUploadModalShow, setIsUploadModalShow] = useState<boolean>(false)

    const getInvoiceTTDData = useCallback(async () => {
      try {
        let query: any = new URLSearchParams({
          limit: String(limit),
          offset: String((currentPage - 1) * limit),
          type: "ttd"
        }).toString()
        const data = await getInvoiceTTD(query)
        // Create a map to track unique ttdIds
        const uniqueTTDIds = new Set<number>();
        // Filter data to include only unique ttdId objects
        const uniqueData = data?.data.filter((obj: { ttdId: number }) => {
          if (uniqueTTDIds.has(obj.ttdId)) {
            return false; // Duplicate ttdId, skip
          }
          uniqueTTDIds.add(obj.ttdId);
          return true; // Unique ttdId, include
        });
        setTotalData(data?.count)
        setData(uniqueData)
      } catch (error) {
        //
      }
    }, [currentPage, limit])
    useEffect(() => {
      getInvoiceTTDData()
    }, [currentPage, getInvoiceTTDData]);

    const closeModalUpload = () => {
      setIsUploadModalShow(false)
    }

    // set data here

    useEffect(() => {
      const tempColumns = [
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='TTD Bulk No' className='min-w-125px' />
          ),
          id: 'inv_id',
          Cell: ({ ...props }) => (
            <span onClick={() => {navigate('../detail-ttd/' +  props?.row?.original?.ttdId)}} style={{ cursor: 'pointer', color: '#6DA8FF', textDecorationLine: 'underline' }}>INV-TTD-{props?.row?.original?.inv?.invDetail[0]?.client?.clientCode}-{dateFormat(props?.row?.original?.created_at, 'mmdd')}{props?.row?.original?.ttdId}</span>
          )
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Client' className='min-w-125px' />
          ),
          id: 'clientName',
          Cell: ({ ...props }) => `${props?.row?.original?.inv?.invDetail[0]?.client?.clientName}`
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='TTD Group' className='min-w-125px' />
          ),
          id: 'ttd_group',
          Cell: ({ ...props }) => `${props?.row?.original?.ttdId}`
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Created At' className='min-w-125px' />
          ),
          id: 'created_at',
          Cell: ({ ...props }) => `${dateFormat(props?.row?.original?.created_at, 'dd-mm-yyyy')}`
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Uploaded File' className='min-w-125px' />
          ),
          id: 'uploaded_file',
          Cell: ({ ...props }) => ( !props?.row?.original?.invDocFile?.includes("no-image") &&
            <div className="row" style={{ width: 50}} title='Download'>
              <div className="col-6" style={{cursor: 'pointer'}}>
                <img src='/media/icons/icon-action/download-icon.svg' onClick={() => {
                  window.open(props?.row?.original?.invDocFile, "_blank")
                }} alt='none'/>
              </div>
            </div>
          )
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Status' className='min-w-125px' />
          ),
          id: 'status',
          Cell: ({ ...props }) => {
            if(props?.row?.original?.paymentStatus === "on_delivery") return "On Delivery"
            else if(props?.row?.original?.paymentStatus === "delivered") return "Delivered"
            else return "Bulk Payment"
          }
        },
        {
          Header: (props: any) => (
            <BrokerCustomHeader tableProps={props} title='Operation' className='w-25px'/>
          ),
          id: 'actions',
          Cell: ({ ...props }) => (
            <div className="row" style={{ width: 50}} title='Upload File'>
              <div className="col-6" style={{cursor: 'pointer'}}>
                <img src='/media/icons/icon-action/ep_edit.svg' onClick={() => {
                  setCurrTTDId(props?.row?.original?.ttdId)
                  setIsUploadModalShow(true)
                }} alt='none'/>
              </div>
              <div className="col-6" style={{cursor: 'pointer'}}>
                <img src='/media/icons/icon-action/download-icon.svg' onClick={() => {
                  InvoiceTTDPDF(props?.row?.original?.ttdId)
                }} alt='none'/>
              </div>
            </div>
          ),
        }
      ];
      setColumns(tempColumns)
    }, [])

    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
      columns,
      data,
    })

    return (
      <>
        <KTCardBody className='py-4'>
          <div className='table-responsive'>
            <table
              id='kt_broker_table'
              className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
              {...getTableProps()}
            >
              <thead>
                <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                  {headers.map((column: ColumnInstance<any>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                {totalData > 0 ? (
                  rows.map((row: any, i) => {
                    prepareRow(row)
                    return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                  })
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        No matching records found
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {!!totalData && (
            <Pagination
              className='pagination-bar'
              currentPage={currentPage}
              totalCount={totalData}
              pageSize={limit}
              onPageChange={(page: any) => setCurrentPage(page)}
            />
          )}
        </KTCardBody>

        {isUploadModalShow && <InvTTDUploadDocModal ttd_id={currTTDId} onCancel={closeModalUpload} />}
      </>
    )
}

export default InvoiceTTDTable