import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../_metronic/helpers'
import {
	JobOrder, JobOrderDetail, JobOrdersQueryResponse, JobOrderUpload,
	JobOrderTracker,
	JobOrderRitase
}
from '../models/job-order-model'
import * as authHelper from 'src/app/context/auth/AuthHelpers'

const API_URL = process.env.REACT_APP_THEME_API_URL
const USER_URL = `${API_URL}/user`
const GET_USERS_URL = `${API_URL}/users/query`

// const GET_DOCUMENT_URL = 'http://10.25.12.134:3000/document'
// const GET_DOCUMENT = 'http://10.25.12.134:3000/document'

const GET_DATA = process.env.REACT_APP_DEV_API_3333
console.log('URL', GET_DATA);


const getJobOrders = (query: string, companyId:string): Promise<JobOrdersQueryResponse> => {
  return axios
	  .get(`${GET_DATA}/joborder?${query}&companyId=${companyId}`)
	  .then((d: AxiosResponse<JobOrdersQueryResponse>) => d.data)
}

const getListJobOrderDocuments = (query: string, id : any): Promise<JobOrdersQueryResponse> => {
	return axios
		.get(`${GET_DATA}/joborder/document?${query}&jobOrderId=${id}`)
		.then((d: AxiosResponse<JobOrdersQueryResponse>) => d.data)
}

const getJobOrderById = (id: any): Promise<JobOrder | undefined> => {
  return axios
	  .get(`${GET_DATA}/joborder/${id}`)
	  .then((response: AxiosResponse<Response<JobOrder>>) => response.data)
	  .then((response: Response<JobOrder>) => response.data)
}

// const getJobOrderDetilById = (id: any): Promise<JobOrder | undefined> => {
// 	return axios
// 		.get(`${GET_DATA}/joborderdetail?jobOrderId=${id}`)
// 		.then((response: AxiosResponse<Response<JobOrder>>) => response.data)
// 		.then((response: Response<JobOrder>) => response.data)
// }



const createJobOrder = (joborder: JobOrder): Promise<JobOrder | undefined> => {
  return axios
	  .put(USER_URL, joborder, {
		headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
	})
	  .then((response: AxiosResponse<Response<JobOrder>>) => response.data)
	  .then((response: Response<JobOrder>) => response.data)
}

const updateJobOrder = (joborder: JobOrder): Promise<JobOrder | undefined> => {
  return axios
	  .post(`${USER_URL}/${joborder.id}`, joborder, {
		headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
	})
	  .then((response: AxiosResponse<Response<JobOrder>>) => response.data)
	  .then((response: Response<JobOrder>) => response.data)
}

const deleteJobOrder = (joborderId: ID): Promise<void> => {
	return axios.delete(`${USER_URL}/${joborderId}`).then(() => {})
}

const deleteSelectedJobOrder= (joborderIds: Array<ID>): Promise<void> => {
	const requests = joborderIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

const createNewData = (joborder: JobOrder) => {

	// console.log(user);

	return axios
		.post(`${GET_DATA}/joborder`, joborder, {
			headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
		})
		.then((response) => {
			return response;
		})

}

const createJobOrderDetail = (joborderdetail: JobOrderDetail) => {

	// console.log(user);

	return axios
		.post(`${GET_DATA}/joborderdetail`, joborderdetail, {
			headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
		})
		.then((response) => {
			return response;
		})

}




const updateNewData = (joborder: JobOrder) => {


	return axios
		.put(`${GET_DATA}/joborder/${joborder.id}`, joborder, {
			headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
		})
		.then((response) => {
			return response;
		})

}

const updateJobOrderDetail = (joborderdetail: JobOrderDetail) => {
	// console.log(joborderdetail)

	return axios
		.put(`${GET_DATA}/joborderdetail/${joborderdetail.id}`, joborderdetail, {
			headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
		})
		.then((response) => {
			return response;
		})

}



const deleteData = (joborder: JobOrder) => {
	return axios
		.delete(`${GET_DATA}/joborder/${joborder.id}`)
		.then((response) => {
			return response;
		})

}

const getDataCompanyName = () => {
	return axios
		.get(`${GET_DATA}/company`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getJobOrderDetilById = (id:any) => {
	return axios
		.get(`${GET_DATA}/joborderdetail?jobOrderId=${id}`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getDataClient = (companyIdUser:any) => {
	return axios
		.get(`${GET_DATA}/client?companyId=${companyIdUser}`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getDataClientById = (id: any) => {
	return axios
		.get(`${GET_DATA}/client/${id}`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getDataDocs = () => {
	return axios
		.get(`${GET_DATA}/document`)
		.then((response) => {
			console.log(response.data.data);
			return response.data.data;
		})
}




// const getListJobOrderDocuments_zikri = (id: any) => {
// 	return axios
// 		.get(`${GET_DATA}/joborder/document?jobOrderId=${id}`)
// 		.then((response) => {
// 			console.log(response.data.data);
// 			return response.data.data;
// 		})
// }

const createDocumentJobOrder = (jobOrderDocument: JobOrderUpload) => {
	return axios.post(`${GET_DATA}/joborder/document`, jobOrderDocument).then((response) => {
		return response
	})
}

const createTrackerJobOrder = (jobOrderTracker: JobOrderTracker) => {
	return axios.post(`${GET_DATA}/joborder/track`, jobOrderTracker).then((response) => {
		return response
	})
}


const deleteDataDocument = (id:any) => {
	return axios
		.delete(`${GET_DATA}/joborder/document/${id}`)
		.then((response) => {
			return response;
		})

}

const getDataArea = () => {
	return axios
		.get(`${GET_DATA}/area?limit=1000`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getDataAreaJob = (id: any, companyId:any) => {
	return axios
		.get(`${GET_DATA}/pricing?limit=1000&id=${id}&companyId=${companyId}`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getDataDetailTracker = (id:any) => {
	return axios
		.get(`${GET_DATA}/joborder/track?jobOrderId=${id}`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getDataJobDocument = (id: any) => {
	return axios
		.get(`${GET_DATA}/joborder/document?jobOrderId=${id}`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const UpdateDocumentJobOrder = (jobOrderDocument: JobOrderUpload, id : any) => {
	console.log(id)

	if(id === '' || id === undefined)
	{
		return axios.post(`${GET_DATA}/joborder/document`, jobOrderDocument).then((response) => {
			return response
		})
	}
	else{
		return axios
			.put(`${GET_DATA}/joborder/document/${id}`, jobOrderDocument)
			.then((response) => {
				return response;
			})
	}


}

const updateTrackerJobOrder = (jobOrderTracker: JobOrderTracker, id:any) => {
	return axios.put(`${GET_DATA}/joborder/track/${id}`, jobOrderTracker).then((response) => {
		return response
	})
}

const getDataTypePriceClient = (id:any) => {
	return axios
		.get(`${GET_DATA}/pricing/client?clientId=${id}&statusJobOrder=1&limit=1000`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getDataKategori = (id: any) => {
	return axios
		.get(`${GET_DATA}/pricing?id=${id}&limit=1000`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}

const getDataJenisOrder = (id: any) => {
	return axios
		.get(`${GET_DATA}/pricingcat/${id}`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}


const getDataPrice = (id: any) => {
	return axios
		.get(`${GET_DATA}/pricing/client/${id}?statusJobOrder=1`)
		.then((response) => {
			// console.log(response.data.data);
			return response.data.data;
		})
}



const getJobOrderExport = (query: string, companyId : any) => {

	// console.log(user);
	const query_replace = query.replace('limit=10','limit=1000')
	// console.log("hasil_replace");
	// console.log(query_replace);

	return axios
		.get(`${GET_DATA}/joborder?${query_replace}&companyId=${companyId}`)
		.then((response) => {
			return response.data.data;
		})

}

const updateRitaseJobOrder = (datas: JobOrderRitase) => {
	return axios.put(`${GET_DATA}/joborder/ritase`, datas, {
		headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
	}).then((response) => {
		return response.data
	}).catch((error) => {
		return error
	})
}

const downloadJobOrder = (id: string) => {
	return axios
		.get(`${GET_DATA}/joborder/download/${id}`, {
			headers: { Authorization: `Bearer ${authHelper.getAuth()}` },
		})
		.then((response) => {
			return response.data;
		})
}





export { getJobOrders, getJobOrderById, createJobOrder,
		updateJobOrder, deleteJobOrder, deleteSelectedJobOrder,
		createNewData, deleteData, updateNewData, getDataCompanyName,
		createJobOrderDetail, updateJobOrderDetail, getJobOrderDetilById,
		getDataClient, getDataClientById, getDataDocs, getListJobOrderDocuments,
		createDocumentJobOrder, deleteDataDocument,
		createTrackerJobOrder, getDataArea,getDataDetailTracker,
		getDataJobDocument,
		UpdateDocumentJobOrder,
		updateTrackerJobOrder,
		getDataTypePriceClient,
		getDataKategori,
		getDataAreaJob,
		getDataJenisOrder,
		getDataPrice,
		getJobOrderExport,
		updateRitaseJobOrder,
		downloadJobOrder
	 }
